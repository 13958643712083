import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovContainer extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  static get observedAttributes() {
    return ['type'];
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }
  get type() {
    return this.type;
  }
  set type(_t) {
    if (_t === 'valid') {
      this.shadowRoot.querySelector('#container').classList.add('valid');
    }
    if (_t === 'warning') {
      this.shadowRoot.querySelector('#container').classList.add('warning');
    }
    if (_t === 'error') {
      this.shadowRoot.querySelector('#container').classList.add('error');
    }
    if (_t === 'disabled') {
      this.shadowRoot.querySelector('#container').classList.add('disabled');
    }
  }
}

if (customElements.get('sagov-container') === undefined) {
  window.customElements.define('sagov-container', SaGovContainer);
}
