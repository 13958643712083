import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovInformationAlert extends BaseSAGOVElement {
  iconTypes = {
    info: 'sagov-info-icon',
    warning: 'sagov-warning-icon',
    error: 'sagov-cross-icon',
    success: 'sagov-tick-icon',
  };

  constructor() {
    super(html);
    this.icon = 'info';
  }

  static get observedAttributes() {
    return ['label', 'icon'];
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  updateLabel() {
    let labelElement = this.shadowRoot.querySelector('#textHolder');

    labelElement.innerHTML = `${this.label}`;
  }

  updateIcon() {
    let iconElement = this.shadowRoot.querySelector('#iconHolder');
    let container = this.shadowRoot.querySelector('#container');

    Object.keys(this.iconTypes).forEach((icon) => {
      container.classList.remove(icon);
    });

    const iconType = this.iconTypes[this.icon];

    if (iconType) {
      iconElement.innerHTML = `<${iconType} size="medium" type="${this.icon}"></${iconType}>`;
      container.classList.add(this.icon);
    }
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(_l) {
    this.updateLabel();
  }

  get icon() {
    return this.getAttribute('icon') || '';
  }

  set icon(_icon) {
    this.updateIcon();
  }

  // if debug is set as an
  get debug() {
    return this.getAttribute('debug') === 'true';
  }
}

if (customElements.get('sagov-information-alert') === undefined) {
  window.customElements.define('sagov-information-alert', SaGovInformationAlert);
}
