import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

/*
    Toggle switch: Allows one of two options to be selected
    Based on a checkbox

    Not checked:    Left side text selected
    Checked:        Right side text selected

    Attributes:
        disabled:   true/false    Control is disabled
        leftText:   Text to show in the left hand side of the control
        rightText:  Text to show in the right hand side of the control
*/
export class SaGovToggleSwitch extends BaseSAGOVElement {
  /*
    Observable attributes
  */
  static get observedAttributes() {
    return ['checked', 'disabled', 'leftText', 'rightText'];
  }

  /*
    Constructor
  */
  constructor() {
    super(html);
  }

  /*
    Control connected event - when control has been created.
    And populate the text from the attributes.
  */
  connectedCallback() {
    const leftLabel = this.shadowRoot.querySelector('.left-text');
    leftLabel.textContent = this.leftText;

    const rightLabel = this.shadowRoot.querySelector('.right-text');
    rightLabel.textContent = this.rightText;

    this.checkbox.addEventListener('change', (event) => {
      const changeEvent = new Event('change', {
        bubbles: true,
        composed: true,
      });
      this.dispatchEvent(changeEvent);
      this.setCheckedAttribute();
    });
  }

  /*
    An attribute has changed event
  */
  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  setCheckedAttribute = () => {
    if (this.checkbox.checked) {
      if (this.getAttribute('checked') !== '') {
        this.setAttribute('checked', '');
      }
    } else {
      this.removeAttribute('checked');
    }
  };

  /*
    Whether an attribute is true or false
   */
  isTrue = (attributeValue) => {
    return attributeValue === true || attributeValue === 'true' || attributeValue === '';
  };

  /*
    Accessor for the left text attribute for the control. If not set by dev then display 'leftText-NotSet'
  */
  get leftText() {
    const text = this.getAttribute('leftText') || 'leftText-NotSet';
    return text;
  }

  /*
    Accessor for the right text attribute for the control. If not set by dev then display 'rightText-NotSet'
  */
  get rightText() {
    const text = this.getAttribute('rightText') || 'rightText-NotSet';
    return text;
  }

  /*
    Accessor for the invisible checkbox.
  */
  get checkbox() {
    const checkbox = this.shadowRoot.querySelector('[type=checkbox]');
    return checkbox;
  }

  /*
    Accessor for the status of the inner checkbox.
    Left side is selected: checked = false
    Right side is selected: checked = true
  */
  get checked() {
    return this.checkbox.checked;
  }

  /*
    Modifier for the status of the inner checkbox.
    Left side is selected: checked = false
    Right side is selected: checked = true
  */
  set checked(flag) {
    if (this.checkbox.checked !== this.isTrue(flag)) {
      this.checkbox.checked = this.isTrue(flag);
      const changeEvent = new Event('change', {
        bubbles: true,
        composed: true,
      });
      this.dispatchEvent(changeEvent);
      this.setCheckedAttribute();
    }
  }

  /*
    Modifier for the control disabled flag.
    Adds or removes the disabled attribute depending on the flag setting.
  */
  set disabled(flag) {
    this.checkbox.disabled = this.isTrue(flag);
  }

  /*
    Whether the control is disabled.
  */
  get disabled() {
    const disabledAttr = this.getAttribute('disabled');
    return this.isTrue(disabledAttr);
  }

  /*
    Whether debug is set as an attribute.
  */
  get debug() {
    const debugAttr = this.getAttribute('debug');
    return this.isTrue(debugAttr);
  }
}

/*
  Adds the control to the windows custom elements - if it does not exist already
*/
if (customElements.get('sagov-toggle-switch') === undefined) {
  window.customElements.define('sagov-toggle-switch', SaGovToggleSwitch);
}
