/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useTable, useExpanded, useFlexLayout, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import { GlobalStrings } from '../../data/global-strings';
import { TableSearch } from './table-search/table-search';
import { TableV2Styled } from './table-stylesv2';
import { useEffect } from 'react';
import { restoreFromUrl, replaceOnUrl, createRestoreScrollPositionHandler } from '../../lib/helpers/url-state-helper';
import { setLoadIsComplete } from '../../lib/helpers/load-complete-helper';
import { NoSearchRows, NoTableRows, TableColumn, TableRow } from './tablev2-components';
import { PaginationV2 } from './pagination/paginationv2';

export function SaGovTableV2(props: {
  columns: any[];
  data: any[];
  SubRow?: ({ row }: { row: any }) => JSX.Element;
  searchFunction: (rows: any[], columns: any[], query: string) => void;
  searchPlaceholderText: string;
  noRowsText: string;
  initialTableState: any;
  icon: JSX.Element;
  tableName: string;
}): JSX.Element {
  // define the props as local variables as the useTable doesn't seem to like them otherwise
  const { columns, data, noRowsText, icon, searchPlaceholderText, tableName, SubRow } = props;

  const {
    headerGroups,
    rows,
    //@ts-ignore
    page,
    //@ts-ignore
    canPreviousPage,
    //@ts-ignore
    canNextPage,
    //@ts-ignore
    pageCount,
    //@ts-ignore
    gotoPage,
    //@ts-ignore
    nextPage,
    //@ts-ignore
    previousPage,
    prepareRow,
    //@ts-ignore
    setGlobalFilter,
    //@ts-ignore
    setSortBy,
    //@ts-ignore
    state: { globalFilter, pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data,
      //@ts-ignore
      globalFilter: props.searchFunction,
      initialState: props.initialTableState,
      noRowsText,
      icon,
    },
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  // use the history to load the current state of the table from a path
  // Step 1: when the table is loaded, rehydrate the state from the query string
  useEffect(() => {
    createRestoreScrollPositionHandler();
    restoreFromUrl([
      (_) => setLoadIsComplete(false),
      (p: URLSearchParams) => setGlobalFilter(decodeURIComponent(p.get('search') ?? '')),
      (p: URLSearchParams) => {
        if (p.get('sortby')) {
          setSortBy([{ id: p.get('sortby') ?? '', desc: p.get('desc') === 'true' }]);
        }
      },
      (p: URLSearchParams) => gotoPage(parseInt(p.get('page') ?? page, 10)),
      (_) => setLoadIsComplete(true),
    ]);
  }, []);

  useEffect(() => {
    replaceOnUrl('page', pageIndex ? pageIndex.toString() : '');
  }, [pageIndex]);

  useEffect(() => {
    replaceOnUrl('search', globalFilter ? encodeURIComponent(globalFilter) : '');
  }, [globalFilter]);

  useEffect(() => {
    if (sortBy.length > 0) {
      replaceOnUrl('sortby', sortBy[0].id);
      replaceOnUrl('desc', sortBy[0].desc ? 'true' : 'false');
    } else {
      replaceOnUrl('sortby', null);
      replaceOnUrl('desc', null);
    }
  }, [sortBy]);

  const downArrow = ' \u25be';
  const upArrow = ' \u25b4';

  /**
   * Get the sort direction indicator for a column.
   */
  const getSortingIndicator = (column: ColumnHeaderType): JSX.Element => {
    let ariaLabel = GlobalStrings.sortDefault;

    if (column.isSorted) {
      ariaLabel = column.isSortedDesc ? GlobalStrings.sortDescending : GlobalStrings.sortAscending;
    }

    /* Add a sort direction indicator */
    return (
      <span role="img" aria-label={ariaLabel}>
        {column.isSorted && column.isSortedDesc && <span className="active-sorting">{downArrow}</span>}
        {column.isSorted && !column.isSortedDesc && <span className="active-sorting">{upArrow}</span>}
        {!column.isSorted && column.canSort && <span className="inactive-sorting">{downArrow}</span>}
      </span>
    );
  };

  /**
   * Capture a sort click event and update sorting as required.
   */
  const updateColumnSort = (column: ColumnHeaderType): void => {
    if (column.canSort) {
      if (!column.isSorted) {
        setSortBy([{ id: column.id, desc: false }]);
      } else if (column.isSorted && !column.isSortedDesc) {
        setSortBy([{ id: column.id, desc: true }]);
      } else if (column.isSorted && column.isSortedDesc) {
        setSortBy([]);
      }
    }
  };

  return (
    <TableV2Styled>
      <div className="search-container">
        <TableSearch
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          placeholderText={searchPlaceholderText}
        />
      </div>
      <div role="grid" id={tableName} tabIndex={-1} data-testid="transaction-table">
        {headerGroups.map(
          (headerGroup: {
            getHeaderGroupProps: () => JSX.IntrinsicAttributes &
              React.ClassAttributes<HTMLTableRowElement> &
              React.HTMLAttributes<HTMLTableRowElement>;
            headers: any[];
          }) => (
            <div role="row" className="table-header" key="table-header">
              {headerGroup.headers.map((column: any, idx: any) => (
                <TableColumn
                  key={idx}
                  className={`col-${idx}`}
                  sortHandler={() => {
                    updateColumnSort(column);
                  }}
                  {...column.getHeaderProps(column.getSortByToggleProps([{ className: column.className }]))}
                >
                  {column.canSort && (
                    <button>
                      {column.render(GlobalStrings.headerProperty)}
                      {getSortingIndicator(column)}
                    </button>
                  )}
                  {!column.canSort && column.render(GlobalStrings.headerProperty)}
                </TableColumn>
              ))}
            </div>
          )
        )}
        {page.length === 0 && !globalFilter && <NoTableRows icon={icon} message={noRowsText} />}
        {page.length === 0 && globalFilter && <NoSearchRows message={GlobalStrings.noSearchResultsText} />}
        {page.length > 0 &&
          page.map((row: RowType, i: number) => {
            // @ts-ignore
            prepareRow(row);
            return (
              <>
                <div role="row" key={i}>
                  {row.cells.map((cell: CellType, idx: number) => {
                    return (
                      <TableRow
                        key={idx}
                        className={`col-${idx}`}
                        {...cell.getCellProps([{ className: cell.column.className }])}
                      >
                        {cell.render(GlobalStrings.cellProperty)}
                      </TableRow>
                    );
                  })}
                </div>
                {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                {row.isExpanded ? (
                  <div key={`subrow-${i}`} className="subrow">
                    {SubRow && <SubRow row={row} />}
                  </div>
                ) : (
                  <React.Fragment key={`subrow-${i}`}></React.Fragment>
                )}
              </>
            );
          })}
      </div>

      <PaginationV2
        rowCount={rows.length}
        pageIndex={pageIndex}
        pageCount={pageCount}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        tableName={tableName}
      />
    </TableV2Styled>
  );
}

// internal type used to define a table cell
interface CellType {
  render: (props: string) => void;
  column: { className: string };
  getCellProps: (c: { className: string }[]) => any;
}

// internal type used to define a table row
interface RowType {
  getRowProps: () => any;
  original: { transactionId: string };
  cells: CellType[];
  isExpanded: boolean;
}

// internal type used for sorting
interface ColumnHeaderType {
  id: string;
  isSorted: boolean;
  isSortedDesc: boolean;
  canSort: boolean;
}
