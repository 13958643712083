import { getToken } from '@dpc-odg/libs-web-auth';
import { CustomerDtoV1 } from '@dpc-odg/libs-shared-customer-profile-api';

export const customerProfileUrl = (baseUrl: string): string => `${baseUrl}/v1/customers/profile`;

/**
 * Fetches current customer profile.
 *
 * @param {string} baseCustomerProfileApiUrl The base URL of the Customer Profile API.
 * @returns {Promise<CustomerDtoV1>} Customer profile data.
 */
export const fetchCustomerProfile = async (baseCustomerProfileApiUrl: string): Promise<CustomerDtoV1> => {
  const token = await getToken();

  const response = await fetch(customerProfileUrl(baseCustomerProfileApiUrl), {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error();
  }
};
