import html from 'raw-loader!./index.html';

export class SaGovLayout extends HTMLElement {
  constructor() {
    super();

    const template = document.createElement('template');
    template.innerHTML = html;

    this.attachShadow({ mode: 'open' }).appendChild(template.content.cloneNode(true));
  }
}
if (customElements.get('sagov-layout') === undefined) {
  window.customElements.define('sagov-layout', SaGovLayout);
}
