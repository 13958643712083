/* eslint-disable @typescript-eslint/no-explicit-any */
import { OpType } from './authentication';
import { KeycloakInitValues } from './models/keycloak-init-values';
import { Queue } from './token-request-queue';

// need to declare the properties that will be on the window so that typescript knows about the window object
declare global {
  interface Window {
    dpc_authqueue: Queue;
    dpc_authstate: OpType;
    dpc_auth: any;
  }
}

// The keycloak class may or may not exist, if we delare that it exists typescript accepts this.
// the functions here are available on the real Keycloak class, which is a native javascript class.
export declare class Keycloak {
  constructor(init: KeycloakInitValues);
  constructor();

  token: string;
  tokenParsed: string;
  idToken: string;
  idTokenParsed: string;
  refreshToken: string;
  refreshTokenParsed: string;

  updateToken(howOften: number): Promise<any>;
  logout({ redirectUri }: any): any;
}

// The keycloak wrapper will hide the native methods from the rest of the code, like keycloak and the window object
export interface KeycloakWrapper {
  get authState(): OpType;
  set authState(state: OpType);
  get authQueue(): Queue;
  get keycloak(): Keycloak;

  keycloakExists(): boolean;
  createKeycloakInstance(initValues?: KeycloakInitValues): Promise<any>;
  log(message: string): void;
}

// The default keycloak wrapper will be used for normal operations, can be overriden for testing
export class DefaultKeycloakWrapper implements KeycloakWrapper {
  authenticationState: OpType = OpType.none;

  keycloakExists(): boolean {
    return typeof Keycloak != 'undefined';
  }

  createKeycloakInstance(initValues?: KeycloakInitValues): Promise<any> {
    if (initValues) {
      window.dpc_auth = new Keycloak(initValues);
    } else {
      window.dpc_auth = new Keycloak();
    }
    return window.dpc_auth.init({
      onLoad: 'login-required',
      pkceMethod: 'S256',
      acr: 'urn:id.gov.au:tdif:acr:ip1:cl1',
    });
  }

  log(message: string): void {
    console.log(message);
  }

  get authQueue(): Queue {
    if (window.dpc_authqueue === undefined) {
      window.dpc_authqueue = new Queue();
    }
    return window.dpc_authqueue;
  }

  get authState(): OpType {
    if (window.dpc_authstate === undefined) {
      window.dpc_authstate = OpType.none;
    }
    return window.dpc_authstate;
  }

  set authState(state: OpType) {
    window.dpc_authstate = state;
  }

  get keycloak(): any {
    return window.dpc_auth;
  }
}
