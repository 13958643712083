import { BaseSAGOVElement } from '../sagov-base-component';
import buttonHtml from 'raw-loader!./button.html';
import listHtml from 'raw-loader!./list.html';

const tabButtonNavigateEvent = 'tab-button-navigate';

export class SaGovTabButton extends BaseSAGOVElement {
  static get observedAttributes() {
    return ['active', 'index'];
  }

  constructor() {
    super(buttonHtml);
    this.eventHandler = this.handleNavigationEvent.bind(this);
  }

  connectedCallback() {
    setTimeout(() => {
      this.button.innerHTML = this.innerHTML;
    }, 0);
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  get button() {
    return this.container.querySelector('button');
  }

  set active(t) {
    if (t == 'true') {
      this.button.classList.add('active');
      this.button.setAttribute('aria-selected', true);
      this.button.tabIndex = 0;
      this.button.addEventListener('keyup', this.eventHandler);
    } else {
      this.button.classList.remove('active');
      this.button.setAttribute('aria-selected', false);
      this.button.tabIndex = -1;
      this.button.removeEventListener('keyup', this.eventHandler);
    }
  }

  // The index is used as a relative position to the whole set of tabs
  // then the keyboard user wants to switch tabs using the arrow keys
  // the index can be incremented or decremented appropriately.
  set index(i) {
    if (i) {
      this.orderindex = parseInt(i, 10);
    } else {
      this.orderindex = undefined;
    }
  }

  get index() {
    return this.orderindex;
  }

  // if focus is called directly, then set this to a focusable element
  // and listen for keyboard navigation events
  focus() {
    this.button.tabindex = 0;
    if (!this.active) {
      this.button.focus();
      this.button.addEventListener('keyup', this.eventHandler);
    }
  }

  // when bluring, reset the keyboard navigation if not active
  blur() {
    this.button.blur();
    if (!this.active) {
      this.button.tabindex = -1;
      this.button.removeEventListener('keyup', this.eventHandler);
    }
  }

  handleNavigationEvent(event) {
    if (this.orderindex === undefined) {
      return;
    }

    let next = this.orderindex;
    if (event.key === 'ArrowLeft') {
      next--;
    } else if (event.key === 'ArrowRight') {
      next++;
    }

    if (next == this.orderindex) {
      return;
    }

    this.dispatchEvent(
      new CustomEvent(tabButtonNavigateEvent, {
        bubbles: true,
        composed: true,
        detail: {
          to: next,
          from: this.orderindex,
        },
      })
    );
  }
}

if (customElements.get('sagov-tab-button') === undefined) {
  window.customElements.define('sagov-tab-button', SaGovTabButton);
}

export class SaGovTabList extends BaseSAGOVElement {
  constructor() {
    super(listHtml);
  }

  connectedCallback() {
    this.addEventListener(tabButtonNavigateEvent, this.navigate);
    setTimeout(() => {
      const tabs = this.querySelectorAll('sagov-tab-button');
      tabs.forEach((tab, index) => {
        tab.setAttribute('index', index);
      });
    }, 0);
  }

  navigate(e) {
    const toTab = this.querySelector(`sagov-tab-button[index="${e.detail.to}"]`);
    // we are either at the start of the end of the line of tabs so nothing to do.
    if (!toTab) {
      return;
    }
    toTab.focus();
  }
}

if (customElements.get('sagov-tab-list') === undefined) {
  window.customElements.define('sagov-tab-list', SaGovTabList);
}
