import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';
import { Constants } from './constants.ts';

export class SaGovDateOfBirth extends BaseSAGOVElement {
  constructor() {
    super(html);
    this.keyup = this.keyup.bind(this);
    this.keydown = this.keydown.bind(this);
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
  }

  static get observedAttributes() {
    return ['label', 'valid', 'errortext', 'value', 'disabled', 'required'];
  }

  connectedCallback() {
    const inputs = this.shadowRoot.querySelectorAll('input');
    inputs.forEach((input) => {
      input.addEventListener('keyup', this.keyup);
      input.addEventListener('keydown', this.keydown);
      input.addEventListener('focus', this.focus);
      input.addEventListener('blur', this.blur);
    });
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  updateLabel() {
    let labelElement = this.shadowRoot.querySelector('#label');

    // handle the required property
    if (this.required) {
      labelElement.innerHTML = `${this.label}<sagov-required></sagov-required>`;
    } else {
      labelElement.innerHTML = this.label;
    }
  }

  isNumeric(pressedKey) {
    return pressedKey >= '0' && pressedKey <= '9';
  }

  goToNextCell(currentCell) {
    const allInputs = [...this.shadowRoot.querySelectorAll('input')];
    const index = allInputs.findIndex((c) => c === currentCell);
    if (index + 1 < allInputs.length) {
      const next = allInputs[index + 1];
      next.focus();
    }
  }

  goToPreviousCell(currentCell) {
    const allInputs = [...this.shadowRoot.querySelectorAll('input')];
    const index = allInputs.findIndex((c) => c === currentCell);
    if (index - 1 >= 0) {
      const next = allInputs[index - 1];
      next.focus();
    }
  }
  /*
    DISABLED
  */
  get disabled() {
    return this.getAttribute('disabled');
  }

  set disabled(_d) {
    const allInputs = [...this.shadowRoot.querySelectorAll('input')];
    if (_d === 'true') {
      allInputs.forEach((i) => i.setAttribute('disabled', true));
    } else {
      allInputs.forEach((i) => i.removeAttribute('disabled'));
    }
  }

  get dayValue() {
    return this.shadowRoot.getElementById('dobDay').value;
  }
  get monthValue() {
    return this.shadowRoot.getElementById('dobMonth').value;
  }
  get yearValue() {
    return this.shadowRoot.getElementById('dobYear').value;
  }
  set dayValue(v) {
    this.shadowRoot.getElementById('dobDay').value = v;
  }
  set monthValue(v) {
    this.shadowRoot.getElementById('dobMonth').value = v;
  }
  set yearValue(v) {
    this.shadowRoot.getElementById('dobYear').value = v;
  }

  // allow access to the value of the input
  get value() {
    return `${this.yearValue}-${this.monthValue}-${this.dayValue}`;
  }

  set value(v) {
    const reg = /^(\d\d\d\d)-(\d\d)-(\d\d)$/;
    const regValue = v.match(reg);
    if (regValue) {
      this.yearValue = regValue[1];
      this.monthValue = regValue[2];
      this.dayValue = regValue[3];
    }
  }

  get valid() {
    return this.getAttribute('valid');
  }

  set valid(_v) {
    const containerClassList = this.container?.classList;
    if (_v === 'true') {
      containerClassList?.remove('error');
    } else if (_v === 'false') {
      containerClassList?.add('error');
    } else {
      containerClassList.remove('error');
    }
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(_l) {
    this.updateLabel();
  }

  get firstCell() {
    return this.shadowRoot.querySelector('input:first-of-type');
  }

  get lastCell() {
    return this.shadowRoot.querySelector('input:last-of-type');
  }

  /*
    ERROR TEXT
  */
  set errortext(error) {
    let errorTextEl = this.shadowRoot.querySelector('.error-text');
    if (error && error.length > 0) {
      errorTextEl.innerText = error;
    } else {
      errorTextEl.innerText = '';
    }
  }

  // On blur we will pad single digits with leading zero for day and month
  blur(event) {
    const blurEventSrcComponent = event.srcElement || event.target;
    if (
      (blurEventSrcComponent.id === 'dobDay' || blurEventSrcComponent.id === 'dobMonth') &&
      blurEventSrcComponent.value.length === 1
    ) {
      blurEventSrcComponent.value = this.padLeadingZeros(blurEventSrcComponent.value);
    }
    //Perform a full date validation when blur
    this.fullDateValidation();
  }

  padLeadingZeros = (fieldValue) => {
    return fieldValue.length === 1 ? '0' + fieldValue : fieldValue;
  };

  focus = (event) => {
    const currentCell = event.srcElement || event.target;
    currentCell.select();
  };
  debounce = (func, timeout = 100) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };

  keyup = (event) => {
    const currentCell = event.srcElement || event.target;
    if (this.isNumeric(event.key)) {
      if (currentCell.maxLength === currentCell.value.length) {
        console.log(currentCell.value.length);
        this.debounce(this.goToNextCell)(currentCell);
      } else {
        console.log('NO');
      }
    }
    this.dateValidation(currentCell);
  };
  keydown = (event) => {
    const currentCell = event.srcElement || event.target;
    const pressedKey = event.key;
    // handle numeric keys
    if (this.isNumeric(pressedKey)) {
      return;
    }

    if (pressedKey !== 'Backspace' && pressedKey !== 'Tab') {
      event.preventDefault();
    }
    // handle special keys
    // note: fallthrough is intended
    switch (pressedKey) {
      case 'Delete':
        if (currentCell.value !== '') {
          currentCell.value = '';
          break;
        }
      case 'Backspace':
        if (currentCell.value.length !== 0) {
          break;
        }
      case 'ArrowLeft':
        this.goToPreviousCell(currentCell);
        break;
      case 'ArrowRight':
        this.goToNextCell(currentCell);
        break;
      case 'Home':
        this.firstCell.focus();
        break;
      case 'End':
        this.lastCell.focus();
        break;
      case 'ArrowUp':
        this.shadowRoot.activeElement.value++;
        break;
      case 'ArrowDown':
        if (this.shadowRoot.activeElement.value > 0) {
          this.shadowRoot.activeElement.value--;
        }
        break;
      default:
        break;
    }
  };

  dayValidation = (dayVal) => {
    return dayVal > 0 && dayVal <= 31;
  };
  monthValidation = (monthVal) => {
    return monthVal > 0 && monthVal <= 12;
  };
  yearValidation = (yearVal) => {
    const fullYear = new Date().getFullYear();
    return yearVal > fullYear - 125 && yearVal <= fullYear;
  };

  fullDateValidation = () => {
    const dayVal = this.padLeadingZeros(this.dayValue);
    const monthVal = this.padLeadingZeros(this.monthValue);
    const length = dayVal.length + monthVal.length + this.yearValue.length;
    if (length === 8) {
      // Check for complete date format if all individual checks are successful.

      const isNotLeapYear =
        new Date(this.value).toLocaleDateString('en-AU') === `${dayVal}/${monthVal}/${this.yearValue}`;
      if (
        this.dayValidation(dayVal) &&
        this.monthValidation(monthVal) &&
        this.yearValidation(this.yearValue) &&
        isNotLeapYear
      ) {
        this.errortext = '';
        this.setAttribute('valid', '');
      } else {
        this.errortext = Constants.dateOfBirthInvalidDate;
        this.setAttribute('valid', 'false');
      }
    } else {
      this.setAttribute('valid', 'false');
    }
    const validDobEvent = new CustomEvent('validdob', {
      detail: { valid: this.valid, value: this.value },
      composed: true,
      bubbles: true,
    });
    this.dispatchEvent(validDobEvent);
  };

  dateValidation = (currentCell) => {
    switch (currentCell.id) {
      case 'dobDay':
        this.dayValidation(currentCell.value)
          ? (this.errortext = '')
          : (this.errortext = Constants.dateOfBirthInvalidDay);
        break;
      case 'dobMonth':
        this.monthValidation(currentCell.value)
          ? (this.errortext = '')
          : (this.errortext = Constants.dateOfBirthInvalidMonth);
        break;
      case 'dobYear':
        this.yearValidation(currentCell.value)
          ? (this.errortext = '')
          : (this.errortext = Constants.dateOfBirthInvalidYear);
        break;
    }
    this.fullDateValidation();
  };

  // required textarea labels have a *
  get required() {
    return this.getAttribute('required') === 'true';
  }

  set required(_r) {
    this.updateLabel();
  }

  // if debug is set as an attribute
  get debug() {
    return this.getAttribute('debug') === 'true';
  }
}

if (customElements.get('sagov-date-of-birth') === undefined) {
  window.customElements.define('sagov-date-of-birth', SaGovDateOfBirth);
}
