import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovLink extends BaseSAGOVElement {
  constructor() {
    super(html);
    this.insertInnerHTML(this.anchor);
  }

  static get observedAttributes() {
    return ['href', 'target', 'disabled', 'type', 'download', 'arialabel'];
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  get anchor() {
    return this.shadowRoot.querySelector('a');
  }

  get wrap() {
    return this.shadowRoot.getElementById('wrap');
  }

  get line() {
    return this.shadowRoot.getElementById('line');
  }

  /*
    HREF
  */
  get href() {
    return this.getAttribute('href');
  }

  set href(href) {
    if (href) {
      this.anchor.setAttribute('href', href);
    }
  }

  /*
    TARGET
  */
  get target() {
    return this.getAttribute('target');
  }

  set target(t) {
    if (t) {
      this.anchor.setAttribute('target', t);
    }
  }

  /*
    DISABLED
  */
  get disabled() {
    return this.wrap.getAttribute('disabled');
  }

  set disabled(_d) {
    if (_d === 'true') {
      this.wrap.classList.add('disabled');
      this.anchor.classList.add('disabled');
      this.anchor.tabIndex = -1;
      this.line.classList.add('disabled');
    } else {
      this.wrap.classList.remove('disabled');
      this.anchor.classList.remove('disabled');
      this.anchor.tabIndex = 0;
      this.line.classList.remove('disabled');
    }
  }

  /*
    TYPE
  */
  get type() {
    return this.anchor.getAttribute('type');
  }

  set type(t) {
    if (t === 'secondary') {
      this.anchor.classList.add(t);
    } else {
      this.anchor.classList.remove('secondary');
    }
  }

  /*
   DOWNLOAD 
   */
  get download() {
    return this.getAttribute('download');
  }

  set download(t) {
    if (t) {
      this.anchor.setAttribute('download', t);
    }
  }

  get arialabel() {
    return this.anchor.getAttribute('aria-label');
  }

  set arialabel(label) {
    this.anchor.setAttribute('aria-label', label);
  }
}

if (customElements.get('sagov-link') === undefined) {
  window.customElements.define('sagov-link', SaGovLink);
}
