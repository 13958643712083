import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

class SaGovTile extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  connectedCallback() {
    const type = this.getAttribute('type');
    if (type === 'tabs') {
      this.container.classList.add(type);
    }

    const size = this.getAttribute('size');
    if (size === 'small' || size === 'noborder') {
      this.container.classList.add(size);
    }
  }
}

if (customElements.get('sagov-tile') === undefined) {
  window.customElements.define('sagov-tile', SaGovTile);
}
