import styled from 'styled-components';

export const TableV2Styled = styled.div`
  div[role='row'] {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    justify-content: space-between;
  }

  div[role='row']:not(:first-child) {
    border-bottom: 1px solid var(--sagov-black-light-05);
  }

  div[role='gridcell'],
  div[role='columnheader'] {
    display: flex;
    align-items: center;
  }

  .table-header {
    background-color: var(--sagov-hover-blue);
  }

  .thead button {
    border: none;
    background: none;
    display: flex;
    font-weight: var(--sagov-font-weight-bold);
  }

  .action-cell {
    display: flex;
    justify-content: center;
  }

  .action-cell:not(.thead) {
    /* Only apply to the data cells, not to the header */
    border-left: 1px solid var(--sagov-black-light-05);
  }

  .bold-text {
    font-weight: var(--sagov-font-weight-bold);
  }

  .toggle-cell {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    align-items: center;
  }

  //The below css should go into the css for table
  .sagov-chevron-right-cell {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 100%;
  }

  .sagov-chevron-right-cell sagov-chevron-right-icon {
    margin-left: var(--sagov-spacer-2);
    cursor: pointer;
  }

  // Sorting

  .active-sorting {
    color: var(--sagov-blue-color);
  }

  .inactive-sorting {
    opacity: 0.5;
  }

  // Child row styles
  .subrow {
    background-color: var(--sagov-grey-color);
  }

  @media (prefers-color-scheme: dark) {
    .subrow {
      background-color: var(--sagov-black-color);
    }
  }

  .subrow-container {
    width: 100%;
  }

  @media (prefers-color-scheme: dark) {
    .subrow {
      background-color: var(--sagov-black-color);
    }
  }

  .subrow-row {
    display: flex;
    padding: var(--sagov-spacer-3);
    gap: var(--sagov-spacer-6);
  }

  .subrow-row .label {
    font-size: var(--sagov-small-font-size);
    margin-bottom: 0;
  }

  .subrow-row .value {
    font-size: var(--sagov-small-font-size);
    font-weight: var(--sagov-font-weight-bold);
  }

  // Child row - mobile
  @media screen and (max-width: 560px) {
    .subrow-row {
      padding: 0;
    }
  }

  // Search styles

  .search-container {
    display: flex;
    justify-content: flex-end;
  }

  .search-container search,
  .search-container sagov-text-input {
    width: 400px;
  }

  // Pagination

  .pagination {
    display: flex;
    align-items: center;
    gap: var(--sagov-spacer-1);
    justify-content: flex-end;
    margin-top: 1rem;
  }
  .page-selector {
    display: flex;
    flex-wrap: nowrap;
  }

  .page-btn {
    background-color: transparent;
    color: var(--sagov-blue-color);
    width: 26px;
    height: 26px;
    cursor: pointer;
    padding: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    border: none;
    justify-content: center;
    margin: 0 4px;
    border-radius: 50%;
  }

  .mobile-page-btn {
    display: none;
  }

  .desktop-page-btn {
    display: flex;
  }

  .page-chevron {
    border: 1px solid var(--sagov-blue-color);
    margin: 0 14px;
  }

  .page-btn.page-chevron[disabled],
  .page-btn.page-chevron[disabled]:hover {
    background-color: var(--sagov-background-color);
    color: var(--sagov-disabled-color);
    border: 1px solid var(--sagov-disabled-color);
    cursor: default;
  }

  .active-page-btn,
  .page-btn:hover {
    background-color: var(--sagov-blue-color);
    color: var(--sagov-white-color);
  }

  .page-btn.ellipsis,
  .page-btn.ellipsis:hover {
    background-color: transparent !important;
    color: var(--sagov-blue-color);
    cursor: default;
  }

  .multi-page-button-container {
    display: flex;
  }

  // Pagination - mobile
  @media screen and (max-width: 560px) {
    .desktop-page-btn {
      display: none;
    }
    .mobile-page-btn {
      display: flex;
    }
    .pagination {
      flex-wrap: wrap;
      flex-direction: row-reverse;
    }

    .pagination > div {
      width: 100%;
    }

    .pagination .page-selector {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .page-btn {
      width: 48px;
      height: 48px;
    }
  }

  // Empty table
  .icon-circle {
    display: inline-block;
    border-radius: 60px;
    background-color: var(--sagov-hover-blue);
    box-shadow: 0 0 1px var(--sagov-black-light-05);
    padding: 0.9em 0.6em;
  }

  .no-transactions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 3%;
  }

  .no-transactions-row {
    background-color: var(--sagov-grey-color);
  }

  @media (prefers-color-scheme: dark) {
    .no-transactions-row {
      background-color: var(--sagov-black-color);
    }
  }

  /* The dashboard breaks this, need to override */
  @media screen and (max-width: 768px) {
    .row {
      flex-direction: row;
    }
  }
`;
