import html from 'raw-loader!./template.html';

const showLogoAttribute = 'show-logo';
const hideLogoClassName = 'hide-logo';

export class SaGovHeader extends HTMLElement {
  constructor() {
    super();

    const template = document.createElement('template');
    template.innerHTML = html;
    this.attachShadow({ mode: 'open' }).appendChild(template.content.cloneNode(true));

    this.handleLogoHiding(this.getAttribute(showLogoAttribute));
  }

  handleLogoHiding(hide) {
    if (hide === 'false') {
      this.logo.classList.add(hideLogoClassName);
    } else {
      this.logo.classList.remove(hideLogoClassName);
    }
  }

  attributeChangedCallback(attributeName, _oldValue, newValue) {
    if (attributeName == showLogoAttribute) {
      this.handleLogoHiding(newValue);
    }
  }

  static get observedAttributes() {
    return [showLogoAttribute];
  }

  get logo() {
    return this.shadowRoot.querySelector('.logo');
  }
}
if (customElements.get('sagov-header') === undefined) {
  window.customElements.define('sagov-header', SaGovHeader);
}
