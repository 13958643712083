import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovSelect extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  static get observedAttributes() {
    return ['name', 'form', 'value', 'label', 'disabled', 'required', 'defaultvalue'];
  }

  connectedCallback() {
    setTimeout(() => {
      this.browserSelect.innerHTML = this.innerHTML;
      this.initialiseSagovSelectMenu();

      [...this.sagovOptions].forEach((option) => {
        option.addEventListener('keydown', this.handleKeydownOnSelectOption);
        option.addEventListener('click', this.handleClickOnSelectOption);
      });

      const selectContainer = this.selectContainer;
      selectContainer.addEventListener('click', this.handleClickOnSelectContainer);
      selectContainer.addEventListener('keydown', this.handleKeydownOnSelectContainer);
      selectContainer.addEventListener('blur', this.handleBlurOnSelectContainer);
      selectContainer.addEventListener('focus', (event) => this.changeChevronType(event, 'default'));
      selectContainer.addEventListener('blur', (event) => this.changeChevronType(event, 'basic'));
      // selectContainer.addEventListener("onchange", this.initialiseSagovSelectMenu);

      const selectButton = selectContainer.querySelector('.select-button');
      selectButton.addEventListener('mouseenter', (event) => this.changeChevronType(event, 'default'));
      selectButton.addEventListener('mouseleave', (event) => this.changeChevronType(event, 'basic'));

      window.setTimeout(() => {
        const options = [...this.sagovOptions];
        const defaultVal = this.browserSelect.getAttribute('defaultvalue');
        const defaultOption = options.filter((o) => o.innerText == defaultVal)[0];

        if (defaultOption) {
          this.selectOption(defaultOption);
        }
      }, 200);
    });
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  // updates the label text
  updateLabel() {
    let labelElement = this.shadowRoot.querySelector('.label-text');

    if (this.required) {
      labelElement.innerHTML = `${this.label}<sagov-required></sagov-required>`;
    } else {
      labelElement.innerHTML = this.label;
    }
  }

  // changes the colour of the dropdown indicator icon
  changeChevronType = (event, type) => {
    if (!event.currentTarget.hasAttribute('disabled')) {
      event.currentTarget.querySelector('sagov-chevron-icon').type = type;
    }
  };

  // handles click event for the options when the menu is open
  handleClickOnSelectOption = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const selectedOption = event.currentTarget;
    this.selectOption(selectedOption);
    if (!selectedOption.hasAttribute('data-disabled')) {
      this.closeSelectMenu();
    }
  };

  // selects the provided option
  selectOption = (selectedOption) => {
    if (selectedOption.hasAttribute('data-disabled')) {
      return;
    }

    for (const option of this.sagovOptions) {
      option.removeAttribute('data-selected');
    }
    selectedOption.setAttribute('data-selected', '');

    this.browserSelect.value = selectedOption.dataset.value;
    const currentlySelectedTextContainer = this.shadowRoot.querySelector('.currently-selected-option');
    currentlySelectedTextContainer.textContent = selectedOption.dataset.label;

    if (this.selectMenuIsOpen()) {
      selectedOption.focus();
    }

    const selectEvent = new CustomEvent('select', {
      detail: {
        value: this.value,
        label: this.label,
        selectedIndex: this.selectedIndex,
      },
      bubbles: true,
      composed: true,
      cancelable: false,
    });
    const inputEvent = new Event('input', { bubbles: true, composed: true });
    this.dispatchEvent(selectEvent);
    this.dispatchEvent(inputEvent);
  };

  // handles keydown events for the options when the menu is open
  handleKeydownOnSelectOption = (event) => {
    const currentOption = event.currentTarget;
    switch (event.code) {
      case 'ArrowUp':
      case 'ArrowLeft':
        event.preventDefault();
        event.stopPropagation();
        this.selectPreviousOption(currentOption);
        break;
      case 'ArrowDown':
      case 'ArrowRight':
        event.preventDefault();
        event.stopPropagation();
        this.selectNextOption(currentOption);
        break;
      case 'Escape':
      case 'Tab':
      case 'Enter':
        event.preventDefault();
        event.stopPropagation();
        this.closeSelectMenu();
        break;
      case 'Space':
        event.preventDefault();
        event.stopPropagation();
        break;
      default:
        break;
    }
  };

  // handles click events on the select button component
  handleClickOnSelectContainer = (event) => {
    if (event.defaultPrevented) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.toggleSelectMenu(false);
  };

  // gets the subset of the given options that are not disabled
  getEnabledOptions() {
    const enabledOptions = [...this.sagovOptions].filter(
      (option) => !option.hasAttribute('data-disabled') && !option.hasAttribute('disabled')
    );
    return enabledOptions;
  }

  // handles keydown events on the select button component
  handleKeydownOnSelectContainer = (event) => {
    switch (event.code) {
      case 'Escape':
        event.preventDefault();
        event.stopPropagation();
        this.closeSelectMenu();
        break;
      case 'Space':
      case 'Enter':
        event.preventDefault();
        event.stopPropagation();
        this.toggleSelectMenu(true);
        break;
      case 'ArrowDown':
      case 'ArrowRight':
        event.preventDefault();
        event.stopPropagation();
        if (event.currentTarget.hasAttribute('disabled')) {
          break;
        }
        if (!this.selectedOption) {
          const firstEnabledOption = this.getEnabledOptions().at(0);
          this.selectOption(firstEnabledOption);
          break;
        }
        this.selectNextOption(this.selectedOption);
        break;
      case 'ArrowUp':
      case 'ArrowLeft':
        event.preventDefault();
        event.stopPropagation();
        if (event.currentTarget.hasAttribute('disabled')) {
          break;
        }
        if (!this.selectedOption) {
          const lastEnabledOption = this.getEnabledOptions().at(-1);
          this.selectOption(lastEnabledOption);
          break;
        }
        this.selectPreviousOption(this.selectedOption);
        break;
      default:
        break;
    }
  };

  // handles the blur event on the select button component
  handleBlurOnSelectContainer = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.closeSelectMenu();
    }
  };

  // whether the select menu is currently open
  selectMenuIsOpen() {
    return this.selectContainer.hasAttribute('data-open');
  }

  /**
   * Opens the sagov select menu
   * @param {boolean} focusSelectedElement - whether the selected element should be focused when opening the menu
   */
  openSelectMenu(focusSelectedElement) {
    const selectMenu = this.shadowRoot.querySelector('.select-menu');
    const selectButton = this.shadowRoot.querySelector('.select-button');
    const borderOffset = parseInt(getComputedStyle(this.selectContainer).borderBottomWidth);
    selectMenu.style.top = selectButton.offsetTop + selectButton.offsetHeight + borderOffset + 'px';

    if (!this.selectContainer.hasAttribute('disabled')) {
      this.selectContainer.setAttribute('data-open', '');
      if (focusSelectedElement) {
        if (this.value !== undefined) {
          const selectedIndex = this.browserSelect.selectedIndex;
          this.sagovOptions[selectedIndex].focus();
        } else {
          const firstEnabledOption = this.getEnabledOptions().at(0);
          this.selectOption(firstEnabledOption);
        }
      }
    }
  }

  // closes the sagov select menu
  closeSelectMenu() {
    if (this.selectContainer.hasAttribute('data-open')) {
      this.selectContainer.removeAttribute('data-open');
      this.selectContainer.focus();
    }
  }

  // toggles the sagov select menu
  toggleSelectMenu(focusSelectedElement) {
    if (this.selectMenuIsOpen()) {
      return this.closeSelectMenu();
    }
    this.openSelectMenu(focusSelectedElement);
  }

  // selects the next enabled option
  selectNextOption(currentlySelectedOption) {
    let newSelection = currentlySelectedOption?.nextElementSibling;
    while (newSelection?.hasAttribute('data-disabled')) {
      newSelection = newSelection.nextElementSibling;
    }
    if (newSelection) {
      this.selectOption(newSelection);
    }
  }

  // selects the previous enabled option
  selectPreviousOption(currentlySelectedOption) {
    let newSelection = currentlySelectedOption?.previousElementSibling;
    while (newSelection?.hasAttribute('data-disabled')) {
      newSelection = newSelection.previousElementSibling;
    }
    if (newSelection) {
      this.selectOption(newSelection);
    }
  }

  // builds and initialises the sagov select menu
  initialiseSagovSelectMenu() {
    const browserSelect = this.browserSelect;
    const selectContainer = this.selectContainer;
    const selectMenu = selectContainer.querySelector('.select-menu');
    const currentlySelectedOption = selectContainer.querySelector('.currently-selected-option');
    currentlySelectedOption.textContent = 'Select from the list';
    browserSelect.selectedIndex = -1;

    // copy attributes of <select> element
    for (let attribute of browserSelect.attributes) {
      selectContainer.dataset[attribute.name] = attribute.value;
    }

    // copy each <option> element
    for (let i = 0; i < browserSelect.options.length; i++) {
      const browserSelectOption = browserSelect.options[i];
      const sagovSelectOption = document.createElement('div');
      sagovSelectOption.classList.add('option');

      // copy attributes the <option>
      for (let attribute of browserSelectOption.attributes) {
        sagovSelectOption.dataset[attribute.name] = attribute.value;
        if (attribute.name === 'selected') {
          browserSelect.selectedIndex = i;
          currentlySelectedOption.textContent = browserSelectOption.textContent;
        }
      }

      sagovSelectOption.dataset.value = browserSelectOption.value;
      sagovSelectOption.dataset.label = browserSelectOption.label;
      sagovSelectOption.tabIndex = -1;

      const sagovSelectOptionLabel = document.createElement('span');
      sagovSelectOptionLabel.classList.add('option-label');
      sagovSelectOptionLabel.innerText = browserSelectOption.label;
      sagovSelectOption.appendChild(sagovSelectOptionLabel);
      selectMenu.appendChild(sagovSelectOption);
    }

    // select the preselected option
    const preselectedValue = this.getAttribute('preselected');
    if (preselectedValue) {
      const selectedOptions = [...this.sagovOptions].filter((option) => option.dataset.value === preselectedValue);
      if (selectedOptions.length >= 1) {
        this.selectOption(selectedOptions.at(-1));
      }
    }
  }

  // gets the browser select element used to keep track of the internal state
  get browserSelect() {
    return this.shadowRoot.querySelector('select');
  }

  // gets the last selected option (browser default behaviour if multiple options have the selected attribute)
  get selectedOption() {
    const selectedOptions = this.shadowRoot.querySelectorAll('.option[data-selected]');
    return [...selectedOptions].at(-1);
  }

  // gets the options in the <sagov-select> menu
  get sagovOptions() {
    return this.shadowRoot.querySelectorAll('.option');
  }

  // gets the select container element
  get selectContainer() {
    return this.shadowRoot.querySelector('.select-container');
  }

  // whether the select element is disabled
  get disabled() {
    return this.browserSelect?.disabled;
  }

  set disabled(value) {
    if (value === 'true' || value === '') {
      this.browserSelect.setAttribute('disabled', value);
      this.selectContainer.setAttribute('disabled', value);
      const lockIcon = document.createElement('sagov-lock-icon');
      lockIcon.setAttribute('type', 'basic');
      const chevronIcon = this.shadowRoot.querySelector('sagov-chevron-icon');
      const parent = this.shadowRoot.querySelector('.select-button');
      parent.insertBefore(lockIcon, chevronIcon);
      chevronIcon.remove();
    }
  }

  // the form that the select element is associated with (if any)
  get form() {
    return this.browserSelect?.form;
  }

  set form(formId) {
    if (!formId) {
      formId = this.getAttribute('form');
    }
    this.browserSelect.setAttribute('form', formId);
    this.selectContainer.setAttribute('data-form', formId);
  }

  get name() {
    return this.browserSelect?.name;
  }

  set name(name) {
    if (name) {
      this.browserSelect.setAttribute('name', name);
    } else {
      this.browserSelect.setAttribute('name', '');
    }
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(_l) {
    this.updateLabel();
  }

  // the value of the selected option
  get value() {
    if (this.browserSelect.selectedIndex < 0) {
      return undefined;
    }
    return this.browserSelect?.value;
  }

  set defaultvalue(val) {
    if (val) {
      this.browserSelect.setAttribute('defaultvalue', val);
    } else {
      this.browserSelect.removeAttribute('defaultvalue');
    }
  }

  // the index of the selected option
  get selectedIndex() {
    return this.browserSelect?.selectedIndex;
  }

  // the label of the selected option
  get selectedLabel() {
    return [...this.browserSelect.selectedOptions].at(0).label;
  }

  // the error text to be displayed when validation fails
  set errortext(error) {
    let selectContainer = this.shadowRoot.getElementById('select');
    let errorTextArea = this.shadowRoot.querySelector('.error-text');
    if (error && error.length > 0) {
      errorTextArea.innerText = error;
      selectContainer.classList.add('error');
    } else {
      selectContainer.classList.remove('error');
    }
  }

  // required select labels have a red asterisk
  get required() {
    return this.getAttribute('required') === 'true';
  }

  set required(_r) {
    this.updateLabel();
  }

  // if debug is set as an attribute
  get debug() {
    return this.getAttribute('debug') === 'true';
  }
}

if (customElements.get('sagov-select') === undefined) {
  window.customElements.define('sagov-select', SaGovSelect);
}
