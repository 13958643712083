import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovCheckbox extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  static get observedAttributes() {
    return ['required', 'name', 'value', 'checked', 'arialabel'];
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  connectedCallback() {
    this.addRequiredLabel();
  }

  addRequiredLabel() {
    let labelElement = this.shadowRoot.querySelector('label');
    const requiredElement = labelElement.querySelector('.req');

    // handle the required property
    if (this.required) {
      if (!requiredElement) {
        const reqspan = document.createElement('span');
        reqspan.classList.add('req');
        reqspan.innerHTML = '&nbsp;<sagov-required aria-hidden="true"></sagov-required>';
        labelElement.appendChild(reqspan);
        this.inputElement().setAttribute('aria-required', true);
      }
    } else {
      if (requiredElement) {
        required.parentElement.remove(required);
        this.inputElement().removeAttribute('aria-required');
      }
    }
  }

  inputElement() {
    return this.shadowRoot.querySelector('input');
  }

  focus() {
    return this.inputElement().focus();
  }

  // allow access to the value of the input
  get value() {
    return this.inputElement().value || '';
  }

  set value(v) {
    if (v) {
      this.inputElement().setAttribute('value', v);
    } else {
      this.inputElement().removeAttribute('value');
    }
  }

  get checked() {
    return this.inputElement().checked || false;
  }

  set checked(v) {
    if (v === 'true') {
      this.inputElement().setAttribute('checked', true);
    } else {
      this.inputElement().removeAttribute('checked');
    }
  }

  set arialabel(l) {
    this.inputElement().setAttribute('aria-label', l);
  }

  get name() {
    return this.inputElement().name || '';
  }

  set name(v) {
    if (v) {
      this.inputElement().setAttribute('name', v);
    } else {
      this.inputElement().removeAttribute('name');
    }
  }

  // required input labels have a *
  get required() {
    return this.getAttribute('required') === 'true';
  }

  set required(_r) {
    this.addRequiredLabel();
  }
}

if (customElements.get('sagov-checkbox') === undefined) {
  window.customElements.define('sagov-checkbox', SaGovCheckbox);
}
