import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovTextInput extends BaseSAGOVElement {
  static formAssociated = true;
  constructor() {
    super(html);
    this.internals = this.attachInternals();
    this.internals.ariaRole = 'textbox';
  }

  static get observedAttributes() {
    return [
      'name',
      'value',
      'errortext',
      'valid',
      'required',
      'label',
      'placeholder',
      'disabled',
      'badgetext',
      'subtext',
      'arialabel',
      'clearlabel',
    ];
  }

  connectedCallback() {
    this.setAttribute('role', 'textbox');
    const input = this.shadowRoot.querySelector('input');
    input.addEventListener('blur', this.setCross.bind(this));
    input.addEventListener('blur', this.handleChange.bind(this));
    input.addEventListener('keypress', this.handleChange.bind(this));
  }

  handleChange() {
    this.setAttribute('value', this.value);
    this.internals.setFormValue(this.value);
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  get inputContainer() {
    return this.shadowRoot.getElementById('input');
  }

  /*
    DISABLED
  */
  get disabled() {
    return this.getAttribute('disabled');
  }

  set disabled(_d) {
    if (_d === 'true') {
      this.shadowRoot.querySelector('input').setAttribute('disabled', true);
      this.inputContainer.classList.add('disabled');

      const lockIcon = document.createElement('sagov-lock-icon');
      lockIcon.setAttribute('type', 'basic');
      this.inputContainer.appendChild(lockIcon);
    } else {
      this.shadowRoot.querySelector('input').removeAttribute('disabled');
      this.shadowRoot.querySelector('sagov-lock-icon').remove();
      this.inputContainer.classList.remove('disabled');
    }
  }

  /*
    VALUE

    Allow access to the value of the input
  */
  get value() {
    return this.shadowRoot.querySelector('input').value;
  }

  set value(v) {
    let input = this.shadowRoot.querySelector('input');
    if (v) {
      input.setAttribute('value', v);
    } else {
      input.setAttribute('value', '');
    }
    // set the cross if there is a value
    this.setCross();
  }

  /* 
    NAME
  */
  get name() {
    return this.shadowRoot.querySelector('input').name;
  }

  set name(n) {
    let input = this.shadowRoot.querySelector('input');
    if (n) {
      input.setAttribute('name', n);
    } else {
      input.setAttribute('name', '');
    }
  }

  /*
    LABEL
  */
  get label() {
    return this.getAttribute('label') || '';
  }

  set label(_l) {
    this.updateLabel();
  }

  updateLabel() {
    let labelElement = this.shadowRoot.querySelector('.label-text');

    if (this.label?.trim()) {
      // handle the required property
      if (this.required) {
        labelElement.innerHTML = `${this.label}<sagov-required></sagov-required>`;
      } else {
        labelElement.innerHTML = this.label;
      }
      labelElement.removeAttribute('aria-hidden');
    } else {
      labelElement.setAttribute('aria-hidden', true);
    }
  }

  /*
    Sub text label
  */
  get subtext() {
    return this.getAttribute('subtext') || '';
  }

  set subtext(n) {
    let input = this.shadowRoot.querySelector('label .sublabel-text');
    input.innerHTML = this.subtext;
  }

  /*
    PLACE HOLDER
  */
  set placeholder(v) {
    let input = this.shadowRoot.querySelector('input');
    if (v) {
      input.setAttribute('placeholder', v);
    } else {
      input.removeAttribute('placeholder');
    }
  }

  /**
    Aria Label
   */
  set arialabel(l) {
    this.shadowRoot.getElementById('container').setAttribute('aria-label', l);
  }

  /**
   * Clear Label
   */
  set clearlabel(l) {}
  get clearlabel() {
    return this.getAttribute('clearlabel') || 'Clear';
  }

  /*
    ERROR TEXT
  */
  set errortext(error) {
    let inputContainer = this.shadowRoot.getElementById('input');
    let errorTextArea = this.shadowRoot.querySelector('.error-text');
    if (error && error.length > 0) {
      const errorMessages = error.split('|');
      errorMessages.forEach((errorMessage) => {
        const errorLink = document.createElement('span');
        errorLink.innerText = errorMessage;

        errorLink.setAttribute('tabindex', '0');

        // when Enter is pressed when focus is on error message, it should point to input text field
        errorLink.addEventListener('keypress', (e) => {
          if (e.key === 'Enter') {
            this.shadowRoot.querySelector('input').focus();
          }
        });

        // Link to input using name
        errorLink.setAttribute('aria-describedby', this.name);

        errorTextArea.appendChild(errorLink);
        errorTextArea.appendChild(document.createElement('br'));
      });

      inputContainer.classList.add('error');
      this.colorBadge();
    } else {
      errorTextArea.innerText = '';
      inputContainer.classList.remove('error');
      this.colorBadge();
    }
  }

  /*
    REQUIRED

    required input labels have a *
  */
  get required() {
    return this.getAttribute('required') === 'true';
  }

  set required(_r) {
    this.updateLabel();
  }

  /*
    VALID

    valid inputs show a tick and green highlight
  */
  get valid() {
    return this.getAttribute('valid') || '';
  }

  set valid(v) {
    let inputContainer = this.shadowRoot.getElementById('input');

    if (v) {
      if (v === 'true') {
        inputContainer.classList.add('valid');
        inputContainer.classList.remove('error');
        this.addTick(inputContainer);
        this.colorBadge();
      } else if (v === 'false') {
        inputContainer.classList.remove('valid');
        inputContainer.classList.add('error');
        this.removeTick();
        this.colorBadge();
      }
    } else {
      inputContainer.classList.remove('valid');
      inputContainer.classList.remove('error');
      this.removeTick();
      this.colorBadge();
    }
  }

  // Adds a tick to the end of an element in the shadow dom
  addTick(element) {
    let tick = this.shadowRoot.querySelector('sagov-tick-icon');
    if (tick) {
      return;
    }
    tick = document.createElement('sagov-tick-icon');
    tick.setAttribute('type', 'success');
    let iconArea = this.shadowRoot.getElementById('iconarea');
    iconArea.appendChild(tick);
  }

  // Removes a tick from the shadow dom if one exists.
  removeTick() {
    let tick = this.shadowRoot.querySelector('sagov-tick-icon');
    if (tick && tick.parentNode !== null) {
      tick.parentNode.removeChild(tick);
    }
  }

  // Adds a cross to the end of the input element
  addCross() {
    let crossBtn = this.shadowRoot.querySelector('button');
    if (crossBtn) {
      return;
    }
    crossBtn = document.createElement('button');
    crossBtn.setAttribute('aria-label', this.clearlabel);
    let cross = document.createElement('sagov-cross-icon');
    cross.setAttribute('title', this.clearlabel);
    cross.setAttribute('aria-hidden', 'true');
    crossBtn.appendChild(cross);

    let iconArea = this.shadowRoot.getElementById('iconarea');
    iconArea.appendChild(crossBtn);
    crossBtn.addEventListener('click', (e) => this.clearField(e));
  }

  // Removes a cross from the shadow dom if one exists.
  removeCross() {
    let crossBtn = this.shadowRoot.querySelector('button');
    if (crossBtn && crossBtn.parentNode !== null) {
      crossBtn.parentNode.removeChild(crossBtn);
    }
  }

  // set the cross based on the value
  setCross() {
    if (this.value) {
      this.addCross();
    } else {
      this.removeCross();
    }
  }

  clearField(e) {
    const input = this.shadowRoot.querySelector('input');
    input.value = null;
    this.removeCross();
    e.preventDefault(); // stop the click bubbling and adding another cross.
    input.focus();
  }

  /*
    BADGES.
  */
  get badge() {
    return this.shadowRoot.querySelector('sagov-tag');
  }

  set badgetext(b) {
    if (b && b.length > 0) {
      this.removeBadge();
      this.addBadge(b);
    } else {
      this.removeBadge();
    }
  }

  addBadge(text) {
    let newBadge = document.createElement('sagov-tag');
    newBadge.innerHTML = text;
    this.shadowRoot.getElementById('badgearea').appendChild(newBadge);
    this.colorBadge(newBadge);
  }

  removeBadge() {
    let badge = this.badge;
    if (badge) {
      badge.parentNode.removeChild(badge);
    }
  }

  colorBadge() {
    let badge = this.badge;
    if (badge) {
      if (this.valid == 'false') {
        badge.setAttribute('type', 'error');
      } else if (this.valid == 'true') {
        badge.setAttribute('type', 'success');
      } else {
        badge.setAttribute('type', '');
      }
    }
  }

  /* 
    DEBUG
  */
  get debug() {
    return this.getAttribute('debug') === 'true';
  }
}

if (customElements.get('sagov-text-input') === undefined) {
  window.customElements.define('sagov-text-input', SaGovTextInput);
}
