/*
  Constants used by pilets to position themselves on the dashboard.
*/
export class Constants {
  static leftSideText = 'left-side';
  static rightSideText = 'right-side';
  static fullWidthText = 'full-width';
  static basePageTitle = 'SA.GOV.AU';
}

/*
  The design system element type for use in IntrinsicElements. 
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DesignSystemHtmlElement = React.DetailedHTMLProps<any, HTMLElement>;
