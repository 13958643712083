/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  Menu,
  Notifications,
  SwitchErrorInfo,
  LoadingIndicatorProps,
  DashboardTileProps,
  LayoutProps,
  ErrorInfoProps,
} from 'piral';
import '../style.scss';
import { DashboardConfigService } from '../services/config-service';
import { Logout } from '../components/logout';
import { Help } from '../components/help';
import { MobileMenu } from '../components/mobile-menu';
import { Constants } from '../constants/constants';
import { DesignSystemHtmlElement } from '@dpc-odg/libs-web-shared';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'sagov-layout': DesignSystemHtmlElement;
      'sagov-header': DesignSystemHtmlElement;
      'sagov-footer': DesignSystemHtmlElement;
    }
  }
}

/* 
  The layout for the whole page. This uses web components from the layout component.
*/
export const layoutPage: React.FunctionComponent<LayoutProps> = ({ children }) => {
  return (
    <section>
      <Notifications />
      <sagov-layout>
        <div id="header" slot="header">
          <div id="banner">
            <span className="banner-bold-text">{Constants.betaBannerText}</span>
            <Constants.headerMessage feedbackFormUrl={DashboardConfigService.FeedbackFormURL} />
          </div>
          <sagov-header>
            <span slot="left-menu">
              <MobileMenu />
            </span>
            <div slot="right-menu">
              <Help />
              <Logout />
            </div>
          </sagov-header>
        </div>
        <div className="main-body">
          <nav role="navigation" className="sidebar">
            <Menu type="general" />
          </nav>
          <main role="main" className="dashboard">
            {children}
          </main>
        </div>

        <div id="footer" slot="footer">
          <sagov-footer accessibility="dashboard/accessibility"></sagov-footer>
        </div>
        <sagov-loading-animation id="loading-spinner" role="progressbar"></sagov-loading-animation>
      </sagov-layout>
    </section>
  );
};

/*
    The template for an individual dashboard tile.
*/
export const layoutTile: React.FunctionComponent<DashboardTileProps> = ({ columns, rows, children }) => (
  <div className={`tile cols-${columns} rows-${rows}`}>{children}</div>
);

/*
    The template for showing error messages.
*/
export const layoutErrorInfo: React.FunctionComponent<ErrorInfoProps> = (props) => (
  <div>
    <h1>{Constants.errorHeader}</h1>
    <SwitchErrorInfo {...props} />
  </div>
);

/*
  The template for showing a loading screen.
*/
export const LayoutLoader: React.FunctionComponent<LoadingIndicatorProps> = () => <></>;
