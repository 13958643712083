import * as React from 'react';
import { GlobalStrings } from '../../../data/global-strings';

// Scroll to the page selector when doing a page operation
// if this is not done, the user will appear at some random point in the page
// when the page resizes with new table content
function scrollToPageSelector(): void {
  setTimeout(() => {
    const pageSelector = document.querySelector('.page-selector');
    if (pageSelector) {
      pageSelector.scrollIntoView();
    }
  }, 0);
}

export const PageSelectorV2 = (props: {
  previousPage: () => void;
  canPreviousPage: boolean;
  pageIndex: number;
  gotoPage: (page: number) => void;
  pageCount: number;
  nextPage: () => void;
  canNextPage: boolean;
}): JSX.Element => {
  const maxPages =
    props.pageCount < GlobalStrings.numberOfPagesToShow ? props.pageCount : GlobalStrings.numberOfPagesToShow;

  const numberedButton = (pageNumber: number, isMobile = false): JSX.Element => {
    const isActive = props.pageIndex == pageNumber - 1;
    const mobileStyle = isMobile ? `mobile-page-btn` : 'desktop-page-btn';
    return (
      <button
        key={pageNumber}
        aria-label={`Navigate to page ${pageNumber}`}
        className={`${mobileStyle} page-btn ${isActive ? 'active-page-btn' : ''}`}
        onClick={() => {
          const nextPage = pageNumber - 1;
          props.gotoPage(nextPage);
          scrollToPageSelector();
        }}
      >
        {pageNumber.toString()}
      </button>
    );
  };

  const ellipsis = (): JSX.Element => {
    return (
      <div key={Math.random()} className={`desktop-page-btn page-btn ellipsis`}>
        {GlobalStrings.ellipsis}
      </div>
    );
  };

  const identifyStartingPageNumber = (): number => {
    const currentPageNumber = props.pageIndex + 1;

    if (currentPageNumber < maxPages - 2) {
      // If the current page is before the second ellipsis, then we're at the start
      return 1;
    } else if (props.pageCount - currentPageNumber < 3) {
      // If the current page less than three pages from the end, we're at the end
      return props.pageCount - (maxPages - 1);
    } else {
      // Otherwise, we're somewhere in the middle, so the starting page is 3 numbers before the current page
      return props.pageIndex - 2;
    }
  };

  return (
    <div className={'page-selector'}>
      <button
        className={`page-btn page-chevron`}
        onClick={() => {
          props.previousPage();
          scrollToPageSelector();
        }}
        disabled={!props.canPreviousPage}
        aria-label={GlobalStrings.previousPage}
      >
        <span aria-hidden="true">&lt;</span>
      </button>

      {numberedButton(props.pageIndex + 1, true)}
      {[...Array(maxPages)].map((el, index) => {
        const startingPage = identifyStartingPageNumber();
        const pageNumber = startingPage + index;

        const firstPageNumber = 1;
        const lastPageNumber = props.pageCount;

        const secondToLastPageIndex = GlobalStrings.numberOfPagesToShow - 2;

        const isSecondButton = index == 1;
        const isSecondToLastButton = index == secondToLastPageIndex;

        const needFirstEllipsis = startingPage != firstPageNumber;
        const needSecondEllipsis = props.pageCount - GlobalStrings.numberOfPagesToShow >= startingPage;

        const isFirstNumberBtn = index == 0;
        const isLastNumberBtn = index == GlobalStrings.numberOfPagesToShow - 1;

        if ((isSecondButton && needFirstEllipsis) || (isSecondToLastButton && needSecondEllipsis)) {
          // If we're second button in and we're too far from the start
          // or we're the second to last button, and too far from the end, need to show ellipsis
          // e.g. 1 [...] 67, 68, 69 [...] 100
          return ellipsis();
        }

        if (isFirstNumberBtn) {
          // Always show the button for the first page
          return numberedButton(firstPageNumber);
        }

        if (isLastNumberBtn) {
          // Always show the button for the last page
          return numberedButton(lastPageNumber);
        }

        // Otherwise, just return the number button
        return numberedButton(pageNumber);
      })}
      <button
        className={`page-btn page-chevron`}
        onClick={() => {
          props.nextPage();
          scrollToPageSelector();
        }}
        disabled={!props.canNextPage}
        aria-label={GlobalStrings.nextPage}
      >
        <span aria-hidden="true">&gt;</span>
      </button>
    </div>
  );
};
