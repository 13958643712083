import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovNoticeLoader extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  connectedCallback() {
    const tag = this.getAttribute('tag');
    if (!tag) {
      throw new Error('notification loader requires "section" attribute');
    }

    // if the url is blank it means we are using this component from a site that is not supported
    const apiUrl = this.getApiUrl();
    fetch(`${apiUrl}/notices/v1/notices/${tag}`)
      .then(async (data) => {
        return data.json();
      })
      .then((data) => {
        console.log(data);
        if (data && data.length > 0) {
          data.forEach((d) => {
            this.createAlert(d.noticeType, d.message);
          });
        }
      })
      .catch((err) => console.error(err));
  }

  /**
   * Create an information alert from the icon an message.
   *
   * @param {string} icon The icon type to display (based on options in sagov-information-alert)
   * @param {string} msg The message to display on the alert.
   */
  createAlert(icon, msg) {
    const alert = document.createElement('sagov-information-alert');
    alert.setAttribute('icon', icon);
    alert.setAttribute('label', msg);
    this.container.appendChild(alert);
  }

  /**
   * Get the base url of the dashboard API based on environment.
   */
  getApiUrl() {
    const hostname = window.location.hostname;
    let prefix = '';

    switch (hostname) {
      case 'localhost':
      case 'sagov-dev.iapplytest.sa.gov.au':
      case 'sagov-dev.iapply.com.au':
      case 'keycloak-dpc-idx.int.ocp.sa.gov.au':
      case 'int.portal.sa.gov.au':
        prefix = 'int.';
        break;
      case 'sagov.iapplytest.sa.gov.au':
      case 'sagov.iapply.com.au':
      case 'keycloak-dpc-idx.stg.ocp.sa.gov.au':
      case 'stg.portal.sa.gov.au':
        prefix = 'stg.';
        break;

      // use prod by default, then any other site can use real data
      default:
        prefix = '';
        break;
    }

    return `https://${prefix}portal.public.api.sa.gov.au`;
  }
}

if (customElements.get('sagov-notice-loader') === undefined) {
  window.customElements.define('sagov-notice-loader', SaGovNoticeLoader);
}
