import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovTextArea extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  static get observedAttributes() {
    return ['name', 'value', 'errortext', 'valid', 'required', 'label', 'placeholder', 'subtext'];
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  updateLabel() {
    let labelElement = this.shadowRoot.querySelector('.label-text');

    // handle the required property
    if (this.required) {
      labelElement.innerHTML = `${this.label}<sagov-required></sagov-required>`;
    } else {
      labelElement.innerHTML = this.label;
    }
  }

  /*
    Adds a tick to the end of an element in the shadow dom
    */
  addTick(element) {
    let tick = this.shadowRoot.querySelector('sagov-tick-icon');
    if (tick) {
      return;
    }
    tick = document.createElement('sagov-tick-icon');
    tick.setAttribute('type', 'success');
    element.appendChild(tick);
  }

  /*
        Removes a tick from the shadow dom if one exists.
    */
  removeTick() {
    let tick = this.shadowRoot.querySelector('sagov-tick-icon');
    if (tick && tick.parentNode !== null) {
      tick.parentNode.removeChild(tick);
    }
  }

  get textAreaContainer() {
    return this.shadowRoot.getElementById('textarea');
  }

  // allow access to the value of the textarea
  get value() {
    return this.shadowRoot.querySelector('textarea').value;
  }

  set value(v) {
    let textarea = this.shadowRoot.querySelector('textarea');
    if (v) {
      textarea.setAttribute('value', v);
    } else {
      textarea.setAttribute('value', '');
    }
  }

  get name() {
    return this.shadowRoot.querySelector('textarea').name;
  }

  set name(n) {
    let textarea = this.shadowRoot.querySelector('textarea');
    if (n) {
      textarea.setAttribute('name', n);
    } else {
      textarea.setAttribute('name', '');
    }
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(_l) {
    this.updateLabel();
  }

  set placeholder(v) {
    let textarea = this.shadowRoot.querySelector('textarea');
    if (v) {
      console.log(v);
      textarea.setAttribute('placeholder', v);
    } else {
      textarea.removeAttribute('placeholder');
    }
  }

  set errortext(error) {
    let textAreaContainer = this.shadowRoot.getElementById('textarea');
    let errorTextArea = this.shadowRoot.querySelector('.error-text');
    if (error && error.length > 0) {
      errorTextArea.innerText = error;
      textAreaContainer.classList.add('error');
    } else {
      textAreaContainer.classList.remove('error');
    }
  }

  /*
   Sub text label
 */
  get subtext() {
    return this.getAttribute('subtext') || '';
  }

  set subtext(n) {
    let input = this.shadowRoot.querySelector('label .sublabel-text');
    input.innerHTML = this.subtext;
  }

  // valid textAreas show a tick and green highlight
  get valid() {
    return this.getAttribute('valid') || '';
  }

  set valid(v) {
    let textAreaContainer = this.shadowRoot.getElementById('textarea');

    if (v) {
      if (v === 'true') {
        textAreaContainer.classList.add('valid');
        textAreaContainer.classList.remove('error');
        this.addTick(textAreaContainer);
      } else if (v === 'false') {
        textAreaContainer.classList.remove('valid');
        textAreaContainer.classList.add('error');
        this.removeTick();
      }
    } else {
      textAreaContainer.classList.remove('valid');
      textAreaContainer.classList.remove('error');
      this.removeTick();
    }
  }

  // required textarea labels have a *
  get required() {
    return this.getAttribute('required') === 'true';
  }

  set required(_r) {
    this.updateLabel();
  }

  // if debug is set as an
  get debug() {
    return this.getAttribute('debug') === 'true';
  }
}

if (customElements.get('sagov-textarea') === undefined) {
  window.customElements.define('sagov-textarea', SaGovTextArea);
}
