const styles = `
  #container {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  svg {
    align-self: center;
    fill: var(--sagov-highlight-color, #273FA9);
    font-weight: var(--sagov-font-weight, 500);
    line-height: var(--sagov-line-height, 165%);
    width: var(--sagov-font-size, 1rem);
    height: var(--sagov-font-size, 1rem);
    padding-left: 5px;
    padding-right: 5px;
  }

  svg.basic {
    fill: var(--sagov-icon-color, #969696);
  }

  svg.black {
    fill: var(--sagov-font-color, #232323);
  }

  svg.success {
    fill: var(--sagov-success-color, #098660);
  }

  svg.error {
    fill: var(--sagov-error-color, #CB2B40);
  }

  svg.warning {
    fill: var(--sagov-warning-color, #FA8B00);
  }

  svg.white{
    fill: var( --sagov-white-color, #FFFFFF);
    width: 0.6rem;
    height: 1rem;
    padding-left: 7px;
  }

  svg.medium {
    width: 1.5rem;
    height: 1.5rem;
  }

  svg.large {
    width: 2rem;
    height: 2rem;
  }

  svg.custom {
    width: 100%;
    height: 100%;
  }

  .rotate {
    animation: rotation 2s;
  }

  .infinite {
    animation-iteration-count: infinite;
  }

  .linear {
    animation-timing-function: linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

let createIcon = (name, svg) => {
  if (customElements.get(name) === undefined) {
    window.customElements.define(
      name,
      class extends HTMLElement {
        constructor() {
          super();

          if (!svg) {
            throw new Error('the svg was not specified.');
          }

          if (svg.slice(0, 5) === '<svg ') {
            svg = svg.slice(0, 5) + 'part="svg" ' + svg.slice(5);
          }

          const template = document.createElement('template');
          template.innerHTML = `<style>${styles}</style><span id='container'>${svg}</span>`;

          this.attachShadow({ mode: 'open' }).appendChild(template.content.cloneNode(true));
        }

        connectedCallback() {
          let svg = this.shadowRoot.querySelector('svg');
          if (this.getAttribute('noleftpad') === 'true') {
            svg.style.paddingLeft = 0;
          }
          if (this.getAttribute('norightpad') === 'true') {
            svg.style.paddingRight = 0;
          }
        }

        // icons have the ability to change the type, either default, success or error.
        // this changes the colour.
        static get observedAttributes() {
          return ['type', 'size', 'title'];
        }

        attributeChangedCallback(attributeName, oldValue, newValue) {
          if (this.debug) {
            console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
          }
          this[attributeName] = newValue;
        }

        get type() {
          return this.getAttribute('type') || 'default';
        }

        set type(value) {
          const svgElement = this.shadowRoot.querySelector('svg');
          const validTypes = ['success', 'warning', 'error', 'default', 'basic', 'white', 'black'];

          svgElement.classList.remove(...validTypes);
          if (validTypes.includes(value)) {
            svgElement.classList.add(value);
          } else {
            svgElement.classList.add('default');
          }
        }

        set size(value) {
          const svgElement = this.shadowRoot.querySelector('svg');
          const validSizes = ['small', 'medium', 'large', 'custom'];

          svgElement.classList.remove(...validSizes);
          if (validSizes.includes(value)) {
            svgElement.classList.add(value);
          }
        }

        set title(value) {
          if (value) {
            const svgElement = this.shadowRoot.querySelector('svg');
            const defaultTitle = svgElement?.querySelector('title');
            if (defaultTitle) {
              defaultTitle.textContent = value;
            } else {
              throw new Error(`Default <title> element does not exist for <svg> element ${name}`);
            }
          }
        }
      }
    );
  }
};

export const tickIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" role="img"><title>Success</title><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/></svg>';
export const alertIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Alert</title><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z"/></svg>';
export const warningIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Warning</title><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M506.3 417l-213.3-364C284.8 39 270.4 32 256 32C241.6 32 227.2 39 218.1 53l-213.2 364C-10.59 444.9 9.851 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM52.58 432L255.1 84.8L459.4 432H52.58zM256 337.1c-17.36 0-31.44 14.08-31.44 31.44c0 17.36 14.11 31.44 31.48 31.44s31.4-14.08 31.4-31.44C287.4 351.2 273.4 337.1 256 337.1zM232 184v96C232 293.3 242.8 304 256 304s24-10.75 24-24v-96C280 170.8 269.3 160 256 160S232 170.8 232 184z"/></svg>';
export const infoIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Information</title><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM296 336h-16V248C280 234.8 269.3 224 256 224H224C210.8 224 200 234.8 200 248S210.8 272 224 272h8v64h-16C202.8 336 192 346.8 192 360S202.8 384 216 384h80c13.25 0 24-10.75 24-24S309.3 336 296 336zM256 192c17.67 0 32-14.33 32-32c0-17.67-14.33-32-32-32S224 142.3 224 160C224 177.7 238.3 192 256 192z"/></svg>';
export const crossIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" role="img"><title>Cross</title><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M393.4 41.37C405.9 28.88 426.1 28.88 438.6 41.37C451.1 53.87 451.1 74.13 438.6 86.63L269.3 255.1L438.6 425.4C451.1 437.9 451.1 458.1 438.6 470.6C426.1 483.1 405.9 483.1 393.4 470.6L223.1 301.3L54.63 470.6C42.13 483.1 21.87 483.1 9.372 470.6C-3.124 458.1-3.124 437.9 9.372 425.4L178.7 255.1L9.372 86.63C-3.124 74.13-3.124 53.87 9.372 41.37C21.87 28.88 42.13 28.88 54.63 41.37L223.1 210.7L393.4 41.37z"/></svg>';
export const loadingIcon =
  '<svg class="rotate infinite linear" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Loading</title><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M304 48C304 74.51 282.5 96 256 96C229.5 96 208 74.51 208 48C208 21.49 229.5 0 256 0C282.5 0 304 21.49 304 48zM304 464C304 490.5 282.5 512 256 512C229.5 512 208 490.5 208 464C208 437.5 229.5 416 256 416C282.5 416 304 437.5 304 464zM0 256C0 229.5 21.49 208 48 208C74.51 208 96 229.5 96 256C96 282.5 74.51 304 48 304C21.49 304 0 282.5 0 256zM512 256C512 282.5 490.5 304 464 304C437.5 304 416 282.5 416 256C416 229.5 437.5 208 464 208C490.5 208 512 229.5 512 256zM74.98 437C56.23 418.3 56.23 387.9 74.98 369.1C93.73 350.4 124.1 350.4 142.9 369.1C161.6 387.9 161.6 418.3 142.9 437C124.1 455.8 93.73 455.8 74.98 437V437zM142.9 142.9C124.1 161.6 93.73 161.6 74.98 142.9C56.24 124.1 56.24 93.73 74.98 74.98C93.73 56.23 124.1 56.23 142.9 74.98C161.6 93.73 161.6 124.1 142.9 142.9zM369.1 369.1C387.9 350.4 418.3 350.4 437 369.1C455.8 387.9 455.8 418.3 437 437C418.3 455.8 387.9 455.8 369.1 437C350.4 418.3 350.4 387.9 369.1 369.1V369.1z"/></svg>';
export const backIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" role="img"><title>Back</title><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"/></svg>';
export const chevronIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" role="img"><title>Chevron</title><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/></svg>';
export const chevronRightIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" role="img"><title>Chevron right</title><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"/></svg>';
export const lockIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" role="img"><title>Lock</title><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 192V128C96 57.31 153.3 0 224 0C294.7 0 352 57.31 352 128V192H368C412.2 192 448 227.8 448 272V432C448 476.2 412.2 512 368 512H80C35.82 512 0 476.2 0 432V272C0 227.8 35.82 192 80 192H96zM128 192H320V128C320 74.98 277 32 224 32C170.1 32 128 74.98 128 128V192zM32 432C32 458.5 53.49 480 80 480H368C394.5 480 416 458.5 416 432V272C416 245.5 394.5 224 368 224H80C53.49 224 32 245.5 32 272V432z"/></svg>';
export const plusIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Plus</title><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M288 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H288V480C288 497.7 273.7 512 255.1 512C238.3 512 223.1 497.7 223.1 480V288H32C14.33 288 0 273.7 0 256C0 238.3 14.33 224 32 224H223.1V32C223.1 14.33 238.3 0 255.1 0C273.7 0 288 14.33 288 32V224z"/></svg>';
export const userIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" role="img"><title>User</title><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"/></svg>';
export const circlePlus =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Circle plus</title><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M240 352V272H160C151.2 272 144 264.8 144 256C144 247.2 151.2 240 160 240H240V160C240 151.2 247.2 144 256 144C264.8 144 272 151.2 272 160V240H352C360.8 240 368 247.2 368 256C368 264.8 360.8 272 352 272H272V352C272 360.8 264.8 368 256 368C247.2 368 240 360.8 240 352zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z"/></svg>';
export const circleMinus =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Circle minus</title><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M352 240C360.8 240 368 247.2 368 256C368 264.8 360.8 272 352 272H160C151.2 272 144 264.8 144 256C144 247.2 151.2 240 160 240H352zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z"/></svg>';
export const circleAlert =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Circle alert</title><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 304c13.25 0 24-10.75 24-24v-128C280 138.8 269.3 128 256 128S232 138.8 232 152v128C232 293.3 242.8 304 256 304zM256 337.1c-17.36 0-31.44 14.08-31.44 31.44C224.6 385.9 238.6 400 256 400s31.44-14.08 31.44-31.44C287.4 351.2 273.4 337.1 256 337.1z"/></svg>';
export const homeIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" role="img"><title>Home</title><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M567.5 229.7C577.6 238.3 578.9 253.4 570.3 263.5C561.7 273.6 546.6 274.9 536.5 266.3L512 245.5V432C512 476.2 476.2 512 432 512H144C99.82 512 64 476.2 64 432V245.5L39.53 266.3C29.42 274.9 14.28 273.6 5.7 263.5C-2.875 253.4-1.634 238.3 8.473 229.7L272.5 5.7C281.4-1.9 294.6-1.9 303.5 5.7L567.5 229.7zM144 464H192V312C192 289.9 209.9 272 232 272H344C366.1 272 384 289.9 384 312V464H432C449.7 464 464 449.7 464 432V204.8L288 55.47L112 204.8V432C112 449.7 126.3 464 144 464V464zM240 464H336V320H240V464z"/></svg>';
export const editIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" role="img"><title>Edit</title><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"/></svg>';
export const circleIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Circle</title><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>';
export const circleQuestionIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Circle question</title><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"/></svg>';
export const fileIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" role="img"><title>File</title><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M0 64C0 28.65 28.65 0 64 0H220.1C232.8 0 245.1 5.057 254.1 14.06L369.9 129.9C378.9 138.9 384 151.2 384 163.9V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM352 192H240C213.5 192 192 170.5 192 144V32H64C46.33 32 32 46.33 32 64V448C32 465.7 46.33 480 64 480H320C337.7 480 352 465.7 352 448V192zM347.3 152.6L231.4 36.69C229.4 34.62 226.8 33.18 224 32.48V144C224 152.8 231.2 160 240 160H351.5C350.8 157.2 349.4 154.6 347.3 152.6z"/></svg>';
export const logoutIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Logout</title><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96C43 32 0 75 0 128V384c0 53 43 96 96 96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H96c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32h64zM504.5 273.4c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22v72H192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32H320v72c0 9.6 5.7 18.2 14.5 22s19 2 26-4.6l144-136z"/></svg>';
export const binIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" role="img"><title>Bin</title><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M144 400C144 408.8 136.8 416 128 416C119.2 416 112 408.8 112 400V176C112 167.2 119.2 160 128 160C136.8 160 144 167.2 144 176V400zM240 400C240 408.8 232.8 416 224 416C215.2 416 208 408.8 208 400V176C208 167.2 215.2 160 224 160C232.8 160 240 167.2 240 176V400zM336 400C336 408.8 328.8 416 320 416C311.2 416 304 408.8 304 400V176C304 167.2 311.2 160 320 160C328.8 160 336 167.2 336 176V400zM310.1 22.56L336.9 64H432C440.8 64 448 71.16 448 80C448 88.84 440.8 96 432 96H416V432C416 476.2 380.2 512 336 512H112C67.82 512 32 476.2 32 432V96H16C7.164 96 0 88.84 0 80C0 71.16 7.164 64 16 64H111.1L137 22.56C145.8 8.526 161.2 0 177.7 0H270.3C286.8 0 302.2 8.526 310.1 22.56V22.56zM148.9 64H299.1L283.8 39.52C280.9 34.84 275.8 32 270.3 32H177.7C172.2 32 167.1 34.84 164.2 39.52L148.9 64zM64 432C64 458.5 85.49 480 112 480H336C362.5 480 384 458.5 384 432V96H64V432z"/></svg>';
export const bellIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" role="img"><title>Bell</title><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 0c-17.7 0-32 14.3-32 32V49.9C119.5 61.4 64 124.2 64 200v33.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V200c0-75.8-55.5-138.6-128-150.1V32c0-17.7-14.3-32-32-32zm0 96h8c57.4 0 104 46.6 104 104v33.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V200c0-57.4 46.6-104 104-104h8zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"/></svg>';
export const listIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>List</title><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM184 72c-13.3 0-24 10.7-24 24s10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H184zm0 160c-13.3 0-24 10.7-24 24s10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H184zm0 160c-13.3 0-24 10.7-24 24s10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H184zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"/></svg>';
export const walletIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Wallet</title><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M88 32C39.4 32 0 71.4 0 120V392c0 48.6 39.4 88 88 88H424c48.6 0 88-39.4 88-88V216c0-48.6-39.4-88-88-88H120c-13.3 0-24 10.7-24 24s10.7 24 24 24H424c22.1 0 40 17.9 40 40V392c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V120c0-22.1 17.9-40 40-40H456c13.3 0 24-10.7 24-24s-10.7-24-24-24H88zM384 336a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>';
export const barsIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" role="img"><title>Bars</title><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 88C0 74.7 10.7 64 24 64H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 112 0 101.3 0 88zM0 248c0-13.3 10.7-24 24-24H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM448 408c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H424c13.3 0 24 10.7 24 24z"/></svg>';
export const bugIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Bug</title><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 0c53 0 96 43 96 96v3.6c0 15.7-12.7 28.4-28.4 28.4H188.4c-15.7 0-28.4-12.7-28.4-28.4V96c0-53 43-96 96-96zM39 103c9.4-9.4 24.6-9.4 33.9 0l72.4 72.4C161.3 165.7 180 160 200 160H312c20 0 38.7 5.7 54.6 15.5L439 103c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72.4 72.4C410.3 225.3 416 244 416 264h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H416v8c0 27.2-6.8 52.8-18.8 75.3L473 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-70.9-70.9C339.3 462.5 299.7 480 256 480s-83.3-17.5-112.2-45.9L73 505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l75.8-75.8C102.8 372.8 96 347.2 96 320v-8H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H96c0-20 5.7-38.7 15.5-54.6L39 137c-9.4-9.4-9.4-24.6 0-33.9zM144 264v56c0 53.6 37.7 98.4 88 109.4V280c0-13.3 10.7-24 24-24s24 10.7 24 24V429.4c50.3-11 88-55.8 88-109.4V264c0-30.9-25.1-56-56-56H200c-30.9 0-56 25.1-56 56z"/></svg>';
export const searchIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Search</title><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z"/></svg>';
export const memoIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" role="img"><title>Memo</title><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64zM0 64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm120 64H264c13.3 0 24 10.7 24 24s-10.7 24-24 24H120c-13.3 0-24-10.7-24-24s10.7-24 24-24zm0 96H264c13.3 0 24 10.7 24 24s-10.7 24-24 24H120c-13.3 0-24-10.7-24-24s10.7-24 24-24zm0 96h48c13.3 0 24 10.7 24 24s-10.7 24-24 24H120c-13.3 0-24-10.7-24-24s10.7-24 24-24z"/></svg>';
export const commentQuestionIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Comment question</title><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M168.2 384.9c-15-5.4-31.7-3.1-44.6 6.4c-8.2 6-22.3 14.8-39.4 22.7c5.6-14.7 9.9-31.3 11.3-49.4c1-12.9-3.3-25.7-11.8-35.5C60.4 302.8 48 272 48 240c0-79.5 83.3-160 208-160s208 80.5 208 160s-83.3 160-208 160c-31.6 0-61.3-5.5-87.8-15.1zM26.3 423.8c-1.6 2.7-3.3 5.4-5.1 8.1l-.3 .5c-1.6 2.3-3.2 4.6-4.8 6.9c-3.5 4.7-7.3 9.3-11.3 13.5c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c5.1 0 10.2-.3 15.3-.8l.7-.1c4.4-.5 8.8-1.1 13.2-1.9c.8-.1 1.6-.3 2.4-.5c17.8-3.5 34.9-9.5 50.1-16.1c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9zM169.8 149.3l-.4 1.2c-4.4 12.5 2.1 26.2 14.6 30.6s26.2-2.1 30.6-14.6l.4-1.2c1.1-3.2 4.2-5.3 7.5-5.3h58.3c8.4 0 15.1 6.8 15.1 15.1c0 5.4-2.9 10.4-7.6 13.1l-44.3 25.4c-7.5 4.3-12.1 12.2-12.1 20.8V248c0 13.3 10.7 24 24 24c13.1 0 23.8-10.5 24-23.6l32.3-18.5c19.6-11.3 31.7-32.2 31.7-54.8c0-34.9-28.3-63.1-63.1-63.1H222.6c-23.7 0-44.8 14.9-52.8 37.3zM288 336a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>';
export const hexagonXmarkIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Hexagon xmark</title><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M16 288c-11.4-19.8-11.4-44.2 0-64L108.3 64.2c11.4-19.8 32.6-32 55.4-32H348.3c22.9 0 44 12.2 55.4 32L496 224c11.4 19.8 11.4 44.2 0 64L403.7 447.8c-11.4 19.8-32.6 32-55.4 32H163.7c-22.9 0-44-12.2-55.4-32L16 288zm27.7-48c-5.7 9.9-5.7 22.1 0 32L136 431.8c5.7 9.9 16.3 16 27.7 16H348.3c11.4 0 22-6.1 27.7-16L468.3 272c5.7-9.9 5.7-22.1 0-32L376 80.2c-5.7-9.9-16.3-16-27.7-16l-184.6 0c-11.4 0-22 6.1-27.7 16L43.7 240zm137-59.3c6.2-6.2 16.4-6.2 22.6 0L256 233.4l52.7-52.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L278.6 256l52.7 52.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L256 278.6l-52.7 52.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L233.4 256l-52.7-52.7c-6.2-6.2-6.2-16.4 0-22.6z"/></svg>';
export const linkIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"><title>Link</title><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M304 24c0 13.3 10.7 24 24 24H430.1L207 271c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l223-223V184c0 13.3 10.7 24 24 24s24-10.7 24-24V24c0-13.3-10.7-24-24-24H328c-13.3 0-24 10.7-24 24zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V440c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z"/></svg>';
export const svgMap = {
  tick: tickIcon,
  alert: alertIcon,
  warning: warningIcon,
  info: infoIcon,
  cross: crossIcon,
  loading: loadingIcon,
  back: backIcon,
  chevron: chevronIcon,
  chevronRight: chevronRightIcon,
  lock: lockIcon,
  plus: plusIcon,
  user: userIcon,
  circlePlus: circlePlus,
  circleMinus: circleMinus,
  circleAlert: circleAlert,
  homeIcon: homeIcon,
  editIcon: editIcon,
  circleIcon: circleIcon,
  circleQuestionIcon: circleQuestionIcon,
  fileIcon: fileIcon,
  logoutIcon: logoutIcon,
  binIcon: binIcon,
  bellIcon: bellIcon,
  listIcon: listIcon,
  walletIcon: walletIcon,
  barsIcon: barsIcon,
  bugIcon: bugIcon,
  searchIcon: searchIcon,
  memoIcon: memoIcon,
  commentQuestionIcon: commentQuestionIcon,
  hexagonXmarkIcon: hexagonXmarkIcon,
  linkIcon: linkIcon,
};

createIcon('sagov-tick-icon', tickIcon);
createIcon('sagov-alert-icon', alertIcon);
createIcon('sagov-warning-icon', warningIcon);
createIcon('sagov-info-icon', infoIcon);
createIcon('sagov-cross-icon', crossIcon);
createIcon('sagov-loading-spinner', loadingIcon);
createIcon('sagov-back-icon', backIcon);
createIcon('sagov-chevron-icon', chevronIcon);
createIcon('sagov-chevron-right-icon', chevronRightIcon);
createIcon('sagov-lock-icon', lockIcon);
createIcon('sagov-plus-icon', plusIcon);
createIcon('sagov-user-icon', userIcon);
createIcon('sagov-circleplus-icon', circlePlus);
createIcon('sagov-circleminus-icon', circleMinus);
createIcon('sagov-circlealert-icon', circleAlert);
createIcon('sagov-home-icon', homeIcon);
createIcon('sagov-edit-icon', editIcon);
createIcon('sagov-circle-icon', circleIcon);
createIcon('sagov-circlequestion-icon', circleQuestionIcon);
createIcon('sagov-file-icon', fileIcon);
createIcon('sagov-logout-icon', logoutIcon);
createIcon('sagov-bin-icon', binIcon);
createIcon('sagov-bell-icon', bellIcon);
createIcon('sagov-list-icon', listIcon);
createIcon('sagov-wallet-icon', walletIcon);
createIcon('sagov-bars-icon', barsIcon);
createIcon('sagov-bug-icon', bugIcon);
createIcon('sagov-search-icon', searchIcon);
createIcon('sagov-memo-icon', memoIcon);
createIcon('sagov-commentquestion-icon', commentQuestionIcon);
createIcon('sagov-hexagonxmark-icon', hexagonXmarkIcon);
createIcon('sagov-link-icon', linkIcon);
