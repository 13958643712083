import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovModal extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  connectedCallback() {
    this.closeButton().addEventListener('click', () => {
      this.dialog().close();
    });
    this.dialog().addEventListener('close', () => {
      this.setAttribute('show', 'false');
      this.hide();
    });
  }

  static get observedAttributes() {
    return ['show', 'heading', 'noclose'];
  }

  closeButton() {
    return this.shadowRoot.getElementById('closeBtn');
  }

  dialog() {
    return this.shadowRoot.querySelector('dialog');
  }

  hide() {
    let closeEvent = new Event('close', {
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(closeEvent);
  }

  set show(s) {
    if (s === 'true') {
      this.dialog().showModal();
      this.container.focus();
    } else {
      if (this.dialog().open) {
        this.dialog().close();
      }
    }
  }

  set heading(t) {
    const titleComponent = this.shadowRoot.getElementById('title');
    titleComponent.innerText = t ? t : '';
  }

  get heading() {
    const titleComponent = this.shadowRoot.getElementById('title');
    return titleComponent.innerText;
  }

  set noclose(show) {
    const closeButton = this.shadowRoot.getElementById('closeBtn');
    if (show === 'true') {
      closeButton.style.display = 'none';
    } else {
      closeButton.style.display = 'flex';
    }
  }
}

if (customElements.get('sagov-modal') === undefined) {
  window.customElements.define('sagov-modal', SaGovModal);
}
