import { createRestoreScrollPositionHandler } from '../../lib/helpers/url-state-helper';

/**
 * Show a spinner that covers the whole dashboard.
 */
function showSpinner(): void {
  const spinner = document.getElementById('loading-spinner') as HTMLElement;
  if (spinner) {
    spinner.style.display = 'block';
  }
}

/**
 * Hide the dashboard spinner.
 */
function hideSpinner(): void {
  const spinner = document.getElementById('loading-spinner') as HTMLElement;
  if (spinner) {
    spinner.style.display = 'none';
  }
}

/**
 * when calling an API or other promise, show a spinner for the duration of the call.
 *
 * @param {Promise<T>} loadHandler The hanlder that needs to be wrapped.
 * @param {(error: boolean) => void} setError The callback to use when setting errors.
 * @returns {Promise<T>}
 */
function wrapLoadWithSpinner<T>(loadHandler: Promise<T>, setError: (error: boolean) => void): Promise<T> {
  return new Promise<T>((resolve, _) => {
    showSpinner();
    createRestoreScrollPositionHandler();
    loadHandler
      .then((result) => {
        resolve(result);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        hideSpinner();
      });
  });
}

/**
 * Helper method to wrap the load with spinner function.
 *
 * @param {(error: boolean) => void} setError The callback to set the error in a pilet.
 * @returns {(loaderHandler: Promise<T>) => Promise<T>}
 */
export function loadWrapper<T>(setError: (error: boolean) => void): (loadHandler: Promise<T>) => Promise<T> {
  return (loadHandler: Promise<T>) => wrapLoadWithSpinner<T>(loadHandler, setError);
}
