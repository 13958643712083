import * as React from 'react';
import { NotificationsHostProps, NotificationsToastProps } from 'piral';

/*
    The template for the container that notifications will be shown in.
*/
export const notificationsHost: React.FunctionComponent<NotificationsHostProps> = ({ children }) => (
  <div className="notifications">{children}</div>
);

/*
    The HTML template for a notifications toast.
*/
export const notificationsToast: React.FunctionComponent<NotificationsToastProps> = ({
  options,
  onClose,
  children,
}) => (
  <div className={`notification-toast ${options.type}`}>
    <div className="notification-toast-details">
      {options.title && <div className="notification-toast-title">{options.title}</div>}
      <div className="notification-toast-description">{children}</div>
    </div>
    <div className="notification-toast-close" onClick={onClose} onKeyPress={onClose} role="button" tabIndex={0} />
  </div>
);
