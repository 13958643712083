import * as React from 'react';
import { GlobalStrings } from '../../../data/global-strings';
import { DesignSystemHtmlElement } from '../../../lib/constants/constants';
import { PageSelectorV2 } from './page-selectorv2';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'sagov-select': DesignSystemHtmlElement;
    }
  }
}

export const PaginationV2 = (props: {
  rowCount: number;
  pageIndex: number;
  pageCount: number;
  previousPage: () => void;
  canPreviousPage: boolean;
  gotoPage: (page: number) => void;
  nextPage: () => void;
  canNextPage: boolean;
  tableName: string;
}): JSX.Element => {
  if (props.rowCount <= GlobalStrings.minRowsForPagination) {
    return <></>;
  }
  return (
    <div className="pagination">
      <PageSelectorV2
        previousPage={props.previousPage}
        canPreviousPage={props.canPreviousPage}
        pageIndex={props.pageIndex}
        gotoPage={props.gotoPage}
        pageCount={props.pageCount}
        nextPage={props.nextPage}
        canNextPage={props.canNextPage}
      />
    </div>
  );
};
