import { SaGovTextInput } from '../sagov-input';

const cover = '0000 000 000';
const phoneLen = 10;

export class SaGovMaskedInput extends SaGovTextInput {
  constructor() {
    super();
  }

  connectedCallback() {
    let input = this.shadowRoot.querySelector('input');

    // add keyup listenter to the input, mask in the input at the point
    input.addEventListener('keyup', this.keyup.bind(this));

    // add keydown listener to the input, prevent invalid keys at this point
    input.addEventListener('keydown', this.keydown.bind(this));

    // make sure the mask is applied when the field is left
    input.addEventListener('blur', this.blur.bind(this));
  }

  keyup(e) {
    if (this.controlKey(e.key)) {
      return;
    }
    this.maskInput(e.key);
  }

  keydown(e) {
    if (this.controlKey(e.key)) {
      return;
    }
    e.preventDefault();
  }

  blur(e) {
    this.applyMask(this.value);
  }

  // Handle the key input for non control keys
  maskInput(key) {
    // determine where the cursor is in the unmasked value
    const calcUnMaskedPosition = (cursor) => {
      var pos = 0;
      for (let i = 0; i < cover.length, i < cursor; i++) {
        if (cover[i] === '0') {
          pos++;
        }
      }
      return pos;
    };

    // conver the cursor to be the masked position value
    const calcMaskedPosition = (cursor) => {
      var pos = 0;
      for (let i = 0; i < cover.length, cursor > 0; i++) {
        if (cover[i] === '0') {
          cursor--;
        }
        pos++;
      }
      return pos;
    };

    let input = this.shadowRoot.querySelector('input');
    var unmaskedCursorPos = calcUnMaskedPosition(input.selectionStart);

    // don't let characters to be added once at the end of the input
    if (unmaskedCursorPos >= phoneLen) {
      return;
    }

    // operate on the demasked value, then remask
    var val = this.deMask(input.value);

    // handle a digit, insert the digit at the current cursor position
    if (key >= '0' && key <= '9') {
      if (val.length >= phoneLen) {
        // override if a complete phone number was entered
        val = val.splice(unmaskedCursorPos, 1, key);
      } else {
        // append if there are still numbers to add
        val = val.splice(unmaskedCursorPos, 0, key);
      }
      unmaskedCursorPos++;
    }

    // apply the mask and reset the cursor position
    this.applyMask(val);
    this.setCaretPosition(input, calcMaskedPosition(unmaskedCursorPos));
  }

  // convert an unmasked value to a masked value
  applyMask(val) {
    var virtualMask = '';
    var vali = 0,
      coveri = 0;
    while (vali < val.length && coveri < cover.length) {
      if (cover[coveri] === '0') {
        virtualMask += val[vali];
        vali++;
        coveri++;
      } else {
        virtualMask += ' ';
        coveri++;
      }
    }

    let input = this.shadowRoot.querySelector('input');
    input.value = virtualMask;
  }

  // Credits: http://blog.vishalon.net/index.php/javascript-getting-and-setting-caret-position-in-textarea/
  setCaretPosition(ctrl, pos) {
    ctrl.focus();
    ctrl.setSelectionRange(pos, pos);
  }

  controlKey(key) {
    // backspace and delete are handled manually
    // if (key == 'Backspace' || key == 'Delete') {
    //   return false;
    // }
    return key.length > 1;
  }

  validKey(key) {
    return /^\d$/.test(key);
  }

  deMask(val) {
    return val.replaceAll(' ', '');
  }

  // override the get value from the input to handle masks
  get value() {
    let v = super.value;
    return this.deMask(v);
  }

  // override the set value from the input to handle masks
  set value(v) {
    this.applyMask(v);
  }
}

if (customElements.get('sagov-mobile-input') === undefined) {
  window.customElements.define('sagov-mobile-input', SaGovMaskedInput);
}

// create a splice for a string
if (!String.prototype.splice) {
  String.prototype.splice = function (idx, rem, str) {
    return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
  };
}
