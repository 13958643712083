import * as React from 'react';
import styled from 'styled-components';

const SectionLinkStyled = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;

  button {
    background-color: var(--sagov-background);
    border: none;
    align-items: center;
    display: flex;
  }
`;

/**
 * Wrapper to make elements keyboard accessible if they don't already support tabbing.
 *
 * @param {JSX.Element} children The element to wrap.
 * @param {string} ariaLabel The label that describes this element to a screen reader.
 * @param {boolean} expanded An optional label to set the expanded attribute if a button relates to an expanding section.
 * @param {title} title An optional title element that will hover over the wrapper button.
 * @returns {JSX.Element}
 */
export const WrapperButton = (props: {
  children: JSX.Element;
  ariaLabel: string;
  expanded?: boolean;
  title?: string;
  clickHandler: () => void;
}): JSX.Element => {
  return (
    <SectionLinkStyled>
      <button
        type="button"
        title={props.title}
        aria-expanded={props.expanded}
        aria-label={props.ariaLabel}
        onClick={props.clickHandler}
      >
        {props.children}
      </button>
    </SectionLinkStyled>
  );
};
