import { AuthOperation } from './models/auth-operation';

/*
  A Queue to allow multiple requests to be sent to the auth mechanism without having 
  race conditions. The queue will be cleared when the authentication is ready. 
*/
export class Queue {
  head: number;
  tail: number;
  elements: AuthOperation[];

  constructor() {
    this.elements = new Array<AuthOperation>();
    this.head = 0;
    this.tail = 0;
  }

  enqueue(element: AuthOperation): void {
    this.elements[this.tail] = element;
    this.tail++;
  }

  dequeue(): AuthOperation {
    const item = this.elements[this.head];
    delete this.elements[this.head];
    this.head++;
    return item;
  }

  peek(): AuthOperation {
    return this.elements[this.head];
  }

  get length(): number {
    return this.tail - this.head;
  }

  get isEmpty(): boolean {
    return this.length === 0;
  }
}
