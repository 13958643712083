/* eslint-disable @typescript-eslint/no-explicit-any */
import { Authentication } from './authentication';
import * as constants from './constants';
import { DefaultKeycloakWrapper } from './keycloak-wrapper';
import { IdToken } from './models/id-token';
import { KeycloakInitValues } from './models/keycloak-init-values';
export * from './models/id-token';
export * from './models/keycloak-init-values';

// create the authentication object
const auth = new Authentication(new DefaultKeycloakWrapper());

// initialise the auth process using configuration values, and a callback on completion.
// eslint-disable-next-line @typescript-eslint/ban-types
export const initAuth = (initValues: KeycloakInitValues, onAuthCompleteCallback: Function): void =>
  auth.initAuth(onAuthCompleteCallback, initValues);

// logout of an existing session
export const logout = (redirectTo: string): void => auth.logout(redirectTo);

// check if keycloak has been loaded on the web page.
export const authIsEnabled = (): boolean => auth.keycloakExists();

// get a JWT access token as a string
export const getToken = (): Promise<string> => auth.authOperation<string>(constants.tokenName);

// get a JWT access token as an JSON object
export const getTokenParsed = (): Promise<any> => auth.authOperation<any>(constants.tokenParsedName);

// get a JWT id token as a string
export const getIdToken = (): Promise<string> => auth.authOperation<string>(constants.idTokenName);

// get a JWT id token as a JSON object
export const getRawIdTokenParsed = (): Promise<any> => auth.authOperation<any>(constants.idTokenParsedName);

// get a JWT id token as an IdToken typescript object
export const getIdTokenParsed = (): Promise<IdToken> => auth.authOperation(constants.idTokenParsedName, mapToIdToken);

// get a JWT refresh token as a string
export const getRefreshToken = (): Promise<string> => auth.authOperation<string>(constants.refreshTokenName);

// get a JWT refresh token as a JSON object
export const getRefreshTokenParsed = (): Promise<any> => auth.authOperation<any>(constants.refreshTokenParsedName);

/**
 * Maps the provided ID Token object to a strongly-typed IdToken.
 *
 * @param {*} idToken The ID Token object to map.
 * @returns {IdToken} The mapped IdToken object.
 */
const mapToIdToken = (idToken: any): IdToken => {
  return {
    Subject: idToken.sub,
    Email: idToken.email,
    FirstName: idToken.given_name,
    LastName: idToken.family_name,
    DateOfBirth: idToken.birthdate,
    IpLevel: idToken.IPLevel,
    AllSubs: idToken.all_subs || [],
    LastLinkDateTime: idToken.last_link_datetime,
  };
};
