/**
 * Create a global event bus using the window object.
 * this code was adapted from https://betterprogramming.pub/poor-mans-event-bus-with-zero-lines-of-code-d4e66fe8f56b
 *
 * NOTE: commented console.log lines are left intentionally in order to debug the events.
 */

/**
 * The callback that will be used for event subscriptions.
 */
export type EventCallback = (data: Event) => void;

// store event handlers that are designated to listen to an event only once
// these will be cleared once the event has been fired
const singleEventMap = new Map();

/**
 * Subscribe to an event.
 *
 * @param {string} eventName The name of the event to subscribe to.
 * @param {EventCallback} callback The function to call if the event fires.
 */
export function subscribeToEvent(eventName: string, callback: EventCallback): void {
  //console.log(`subscribing to event ${eventName}`);
  window.addEventListener(eventName, callback);
}

/**
 * Subscribe to an event for a single fire only.
 *
 * @param {string} eventName The name of the event to subscribe to.
 * @param {EventCallback} callback The function to call if the event fires.
 */
export function subscribeToEventOnce(eventName: string, callback: EventCallback): void {
  //console.log(`subscribing to event once ${eventName}`);
  if (singleEventMap.get(eventName) === undefined) {
    singleEventMap.set(eventName, []);
  }
  const singleEventListeners = singleEventMap.get(eventName);
  singleEventListeners.push(callback);

  window.addEventListener(eventName, callback);
}

/**
 * Remove a subscription from an event.
 *
 * @param {string} eventName The name of the event to remove subscription from.
 * @param {EventCallback} callback The function to detach.
 */
export function detachFromEvent(eventName: string, callback: EventCallback): void {
  //console.log(`detaching from event ${eventName}`);
  window.removeEventListener(eventName, callback);
}

/**
 * Emit an event
 *
 * @param {string} eventName The name of the event.
 * @param {T} data The data to include in the event.
 */
export function emitEvent<T>(eventName: string, data: T): void {
  //console.log(`emitting event ${eventName}`);
  window.dispatchEvent(
    new CustomEvent(eventName, {
      detail: data,
    })
  );
  const singleEvents = singleEventMap.get(eventName);
  if (singleEvents) {
    singleEvents.forEach((element: EventCallback) => {
      detachFromEvent(eventName, element);
    });
    singleEventMap.delete(eventName);
  }
}
