import * as React from 'react';
import { Dict, MenuContainerProps, MenuItemProps, MenuItemRegistration, useGlobalState } from 'piral';
import { NavLink } from 'react-router-dom';
import { DesignSystemHtmlElement } from '@dpc-odg/libs-web-shared';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'sagov-home-icon': DesignSystemHtmlElement;
    }
  }
}

/*
  Template to draw a single menu item.
*/
export const MenuItem: React.FunctionComponent<MenuItemProps> = ({ children }) => (
  <div className="menu-item">{children}</div>
);

/*
  The layout that shows ALL menu items.
*/
export const MenuLayout: React.FC<MenuContainerProps> = ({ children }) => {
  const menuItems = useGlobalState((m) => m.registry.menuItems);

  const sortedChildren = React.Children.map(children, (child) => extractChild(menuItems, child)).sort(
    (a, b) => a.menuItem.settings.order - b.menuItem.settings.order
  );

  return (
    <div className="menu-item-container">
      <div className="menu-item">
        <NavLink to="/">
          <sagov-home-icon aria-hidden="true" /> Home
        </NavLink>
      </div>
      {sortedChildren.map((m) => m.child)}
    </div>
  );
};

// there is no valid return type for this function
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const extractChild = (menuItems: Dict<MenuItemRegistration>, child: React.ReactNode) => {
  if (React.isValidElement(child)) {
    const menuItem = menuItems[child.key];

    return {
      menuItem,
      child,
    };
  }

  throw `MenuItem is not a valid element`;
};
