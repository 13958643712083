import * as React from 'react';
import { DesignSystemHtmlElement } from '../../lib/constants/constants';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'pilet-loading-spinner': DesignSystemHtmlElement;
    }
  }
}

/**
 * Component to show when an unrecoverable error occurs on a pilet.
 *
 * @param {string} errorLabel The message to show on the error.
 * @returns {JSX.Element}
 */
export function ComponentLoadSpinner(): JSX.Element {
  return <sagov-loading-spinner role="progressbar"></sagov-loading-spinner>;
}
