/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeycloakWrapper } from './keycloak-wrapper';
import { AuthOperation } from './models/auth-operation';
import { KeycloakInitValues } from './models/keycloak-init-values';

/* Private Section */
export enum OpType {
  none,
  init,
  ready,
  loading,
}

export class Authentication {
  readonly kc: KeycloakWrapper;

  constructor(kc: KeycloakWrapper) {
    this.kc = kc;
  }

  /* Public Functions */
  keycloakExists(): boolean {
    return this.kc.keycloakExists();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/unbound-method
  authOperation<Type>(op: string, mapper: Function = this.mapToObject): Promise<Type> {
    return this.internalAuthOp(op, mapper);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  initAuth(onAuthCompleteCallback: Function, initValues?: KeycloakInitValues): void {
    this._initAuth(onAuthCompleteCallback, initValues);
  }

  logout(redirectTo: string): void {
    this._logout(redirectTo);
  }

  /* Private Functions */
  // eslint-disable-next-line @typescript-eslint/unbound-method, @typescript-eslint/ban-types
  private internalAuthOp<Type>(op: string, mapper: Function = this.mapToObject): Promise<Type> {
    if (!this.keycloakExists()) {
      throw new Error(
        'Authentication is not enabled, but a component is attempting to use it. Before calling to get tokens, call authIsEnabled().'
      );
    }

    return new Promise<Type>((resolve: any, reject: any) => {
      const queuedOp: AuthOperation = {
        operation: op,
        success: resolve,
        failure: reject,
        mapper: mapper,
      };
      this.kc.authQueue.enqueue(queuedOp);
      this.initKeycloak(() => true);
    });
  }

  private mapToObject(token: any): void {
    return token;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private initKeycloak(onAuthCompleteCallback: Function, initValues?: KeycloakInitValues): void {
    if (!this.kc.keycloakExists()) {
      throw new Error('Attempt to initialise keycloak, but no keycloak is available.');
    }

    // the auth process is complete
    if (this.kc.authState === OpType.ready) {
      onAuthCompleteCallback && onAuthCompleteCallback(true);

      this.kc.keycloak
        .updateToken(30)
        .then(
          (() => {
            this.clearAuthQueueWithSuccess();
          }).bind(this)
        )
        .catch(
          (() => {
            this.clearAuthQueueWithFailure('Failed to refresh token');
          }).bind(this)
        );
      return;
    }

    // the auth process is already in progress.
    if (this.kc.authState === OpType.loading) {
      return;
    }

    this.kc.authState = OpType.loading;
    this.kc
      .createKeycloakInstance(initValues)
      .then((authenticated: boolean) => {
        if (!authenticated) {
          this.kc.authState = OpType.none;
          onAuthCompleteCallback && onAuthCompleteCallback(true);
          this.clearAuthQueueWithFailure('not authenticated');
        } else {
          this.kc.log(`authenticated: ${authenticated}`);

          this.kc.authState = OpType.ready;

          // send back word that we have authenticated for those that care
          onAuthCompleteCallback && onAuthCompleteCallback(true);
          this.clearAuthQueueWithSuccess();
        }
      })
      .catch(() => {
        console.error('failed to initialise keycloak');
        this.clearAuthQueueWithFailure('');
      });
  }

  private clearAuthQueueWithSuccess(): void {
    while (!this.kc.authQueue.isEmpty) {
      const op = this.kc.authQueue.dequeue();
      let token = (this.kc.keycloak as any)[op.operation];
      token = op.mapper(token);
      op.success(token);
    }
  }

  private clearAuthQueueWithFailure(e: any): void {
    while (!this.kc.authQueue.isEmpty) {
      const op = this.kc.authQueue.dequeue();
      op.failure(e);
    }
  }

  // setup the auth module, this is done at the window level so that it is global across
  // modules. A module can do this manually, or when the dom is loaded.
  // eslint-disable-next-line @typescript-eslint/ban-types
  private _initAuth(onAuthCompleteCallback: Function, initValues?: KeycloakInitValues): void {
    // only init if the auth state is undefined
    if (this.kc.authState === OpType.none) {
      if (this.kc.keycloakExists()) {
        this.kc.log('Initiating keycloak.');
        this.kc.authState = OpType.init;
        this.initKeycloak(onAuthCompleteCallback, initValues);
      } else {
        this.kc.log('warning: no auth installed for this page.');
        if (onAuthCompleteCallback != null) {
          onAuthCompleteCallback(false);
        }
      }
    }
  }

  private _logout(redirectTo: string): void {
    if (this.kc.authState === OpType.ready) {
      this.kc.keycloak.logout({ redirectUri: redirectTo });
    } else {
      throw new Error('Attempt to logout when not logged in.');
    }
  }
}
