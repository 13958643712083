import * as React from 'react';
import { DesignSystemHtmlElement } from '../../lib/constants/constants';
import { setLoadIsComplete } from '../../lib/helpers/load-complete-helper';
import { useEffect, useState } from 'react';
import { createRestoreScrollPositionHandler } from '../../lib/helpers/url-state-helper';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'sagov-widget-error': DesignSystemHtmlElement;
      'sagov-loading-spinner': DesignSystemHtmlElement;
      'sagov-loading-animation': DesignSystemHtmlElement;
    }
  }
}

interface PiletLoaderParams {
  error: boolean;
  loaded: boolean;
  errorLabel: string;
  component: JSX.Element;
  busy?: boolean;
}

/**
 * Allow the pilet loader and loading states to be managed in a use block.
 *
 * @param errorLabel The error to show when the save fails.
 * @param initialLoad The load handler for loading data when the pilet is created.
 * @returns The pilet loader component.
 */
export const usePiletLoader = (
  errorLabel: string,
  initialLoad: () => Promise<void>,
  setLoadedCallback?: () => void
): {
  PiletLoader: React.FC<{ children: JSX.Element }>;
  load: <T>(loadHandler: Promise<T>) => Promise<T>;
} => {
  const [error, setError] = useState(false);
  const [initalLoadComplete, setInitialLoadComplete] = useState(false);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    initialLoad()
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setInitialLoadComplete(true);
        if (setLoadedCallback) {
          setLoadedCallback();
        }
      });
  }, []);

  return {
    PiletLoader: (props: { children: JSX.Element }): JSX.Element => (
      <PiletLoader
        error={error}
        loaded={initalLoadComplete}
        errorLabel={errorLabel}
        component={props.children}
        busy={busy}
      ></PiletLoader>
    ),
    load: <T,>(loadHandler: Promise<T>): Promise<T> => {
      return new Promise<T>((resolve, _) => {
        setBusy(true);
        loadHandler
          .then((result) => {
            resolve(result);
          })
          .catch(() => {
            setError(true);
          })
          .finally(() => {
            setBusy(false);
          });
      });
    },
  };
};

/**
 * Component to handle the default loading behaviour for a pilet.
 */
export const PiletLoader = ({ error, errorLabel, loaded, component, busy = false }: PiletLoaderParams): JSX.Element => {
  // if we are loading, we are scrolling, add the handler to restore the scroll position.
  useEffect(() => {
    createRestoreScrollPositionHandler();
  }, []);

  // set the load complete value, this determines when the scroll handler is run based on ALL loaders.
  useEffect(() => {
    setLoadIsComplete(loaded);
  }, [loaded]);

  if (busy) {
    return (
      <>
        <sagov-loading-animation role="busy"></sagov-loading-animation>
        {component}
      </>
    );
  } else if (error) {
    return <sagov-widget-error role="error" data-testid="pilet-loader-error" label={errorLabel}></sagov-widget-error>;
  } else if (!loaded) {
    return <sagov-loading-spinner role="busy" data-testid="pilet-loader-loading-spinner"></sagov-loading-spinner>;
  }

  return component;
};
