import { ComponentsState } from 'piral';
import { DashboardContainer } from './layout/layout-container';
import { layoutErrorInfo, LayoutLoader, layoutPage, layoutTile } from './layout/layout-parts';

import { notificationsHost, notificationsToast } from './layout/layout-notifications';
import { MenuItem, MenuLayout } from './layout/layout-menu';

/*
  Register all the layout components that will be used in the dashboard.
*/
export const layout: Partial<ComponentsState> = {
  // layout components
  ErrorInfo: layoutErrorInfo,
  DashboardContainer: DashboardContainer,
  DashboardTile: layoutTile,
  Layout: layoutPage,
  LoadingIndicator: LayoutLoader,

  // menu components
  MenuContainer: MenuLayout,
  MenuItem: MenuItem,

  // notification components
  NotificationsHost: notificationsHost,
  NotificationsToast: notificationsToast,
};
