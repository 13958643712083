import { getTokenParsed } from '@dpc-odg/libs-web-auth';
import { EzyregVerified, hasRealmAccessRole } from '@dpc-odg/libs-shared-auth';

/**
 * Gets the token and tests if the user has the EZYREG_VERFIED role or not.
 * @returns true if the role is present, false otherwise.
 */
export async function userIsEzyRegVerified(): Promise<boolean> {
  const token = await getTokenParsed();
  return hasRealmAccessRole(token, EzyregVerified);
}
