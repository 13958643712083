import { CustomerDtoV1 } from '@dpc-odg/libs-shared-customer-profile-api';
import { fetchCustomerProfile } from './customer-profile-service';
import { WebEvents, emitEvent } from '@dpc-odg/libs-web-shared';

enum CustomerLoadState {
  LoadNotStarted,
  Loading,
  Error,
  Cached,
}

let customerProfile: CustomerDtoV1 | undefined;
let customerLoadState: CustomerLoadState = CustomerLoadState.LoadNotStarted;

/**
 * Loads customer data from the specified API.
 *
 * @param {string} customerProfileApi The API endpoint for fetching customer profiles.
 * @returns {(CustomerDtoV1 | null)} The loaded customer profile or `null` if loading is in progress.
 */
export function loadCustomer(customerProfileApi: string): CustomerDtoV1 | null {
  // send the customer straight back if they are already loaded
  if (customerProfile && customerLoadState === CustomerLoadState.Cached) {
    return customerProfile;
  }

  if (customerLoadState == CustomerLoadState.LoadNotStarted) {
    customerLoadState = CustomerLoadState.Loading;
    fetchCustomerProfile(customerProfileApi)
      .then((customer) => {
        customerLoadState = CustomerLoadState.Cached;
        customerProfile = customer;
        emitEvent(WebEvents.CustomerUpdated, customer);
      })
      .catch((error) => {
        customerLoadState = CustomerLoadState.Error;
        customerProfile = undefined;
        emitEvent(WebEvents.CustomerLoadFailed, { error: error });
      });
  }

  // no customer yet, but loading, so return and let
  // the ui wait for an event
  return null;
}

/**
 * Clear the cached customer and reset ready for a reload.
 */
export function clearCustomerCache(): void {
  customerProfile = undefined;
  customerLoadState = CustomerLoadState.LoadNotStarted;
}
