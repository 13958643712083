import { AccessToken } from '../models/token';

/**
 * Checks if a given role is set on the token.
 *
 * @param {AccessToken} token The access token containing realm access information.
 * @param {string} role The role to check for.
 * @returns {boolean} True if the role is present in the realm access roles, otherwise false.
 */
export const hasRealmAccessRole = (token: AccessToken, role: string): boolean => {
  if (token.realm_access && token.realm_access.roles) {
    return token.realm_access.roles.includes(role);
  }
  return false;
};
