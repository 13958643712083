export class BaseSAGOVElement extends HTMLElement {
  constructor(templateHtml) {
    super();

    if (!templateHtml) {
      throw new Error('the initial template html needs to be specified.');
    }

    const template = document.createElement('template');
    template.innerHTML = templateHtml;

    this.attachShadow({ mode: 'open' }).appendChild(template.content.cloneNode(true));
  }

  attrIsTrue = (attributeValue) => {
    return attributeValue === true || attributeValue === 'true' || attributeValue === '';
  };

  setNotNullAttribute(el, name) {
    const attr = this.getAttribute(name);
    if (attr) {
      el.setAttribute(name, attr);
    } else {
      el.setAttribute(name, '');
    }
  }

  get container() {
    return this.shadowRoot.getElementById('container');
  }

  insertInnerHTML(el, additional = '') {
    // setTimeout creates a queue and enables us to call this in
    // connectedCallback as the innerHTML is not loaded at that stage
    setTimeout(() => (el.innerHTML = `${additional}${this.innerHTML}`));
  }
}

/*
  Create a simple component that only contains styles and layout
*/
export function createSimpleComponent(html, name) {
  if (customElements.get(name) === undefined) {
    window.customElements.define(
      name,
      class extends BaseSAGOVElement {
        constructor() {
          super(html);
        }
      }
    );
  }
}
