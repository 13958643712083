import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovCodeVerifier extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  static get observedAttributes() {
    return ['label', 'valid', 'errortext'];
  }

  connectedCallback() {
    const inputs = this.shadowRoot.querySelectorAll('input');
    inputs.forEach((input) => {
      input.addEventListener('keyup', this.keyup);
      input.addEventListener('keydown', this.keydown);
      input.addEventListener('focus', this.focus);
    });
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  updateLabel() {
    const labelElement = this.shadowRoot.querySelector('#label');

    labelElement.innerHTML = `${this.label}`;
  }

  isAlphaNumeric(pressedKey) {
    const key = pressedKey.toLowerCase();
    if (key.length != 1) {
      return false;
    }
    return (key >= 'a' && key <= 'z') || (key >= '0' && key <= '9');
  }

  goToNextCell(currentCell) {
    if (currentCell.nextElementSibling) {
      let next = currentCell.nextElementSibling;
      if (next.tagName.toLowerCase() === 'input') {
        next.focus();
      }
    }
  }

  goToPreviousCell(currentCell) {
    if (currentCell.previousElementSibling) {
      let prev = currentCell.previousElementSibling;
      if (prev.tagName.toLowerCase() === 'input') {
        prev.focus();
      }
    }
  }

  // allow access to the value of the input
  get value() {
    const inputs = this.shadowRoot.querySelectorAll('input');

    let val = '';
    inputs.forEach((input) => {
      val += input.value;
    });
    return val.slice(0, 6);
  }

  get valid() {
    return this.valid;
  }

  set valid(_v) {
    if (_v === 'true') {
      this.shadowRoot.getElementById('container').classList.remove('error');
      this.shadowRoot.getElementById('container').classList.add('success');
    } else if (_v === 'false') {
      this.shadowRoot.getElementById('container').classList.remove('success');
      this.shadowRoot.getElementById('container').classList.add('error');
    } else {
      this.shadowRoot.getElementById('container').classList.remove('success');
      this.shadowRoot.getElementById('container').classList.remove('error');
    }
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(_l) {
    this.updateLabel();
  }

  get firstCell() {
    return this.shadowRoot.querySelector('input:first-of-type');
  }

  get lastCell() {
    return this.shadowRoot.querySelector('input:last-of-type');
  }

  /*
    ERROR TEXT
  */
  set errortext(error) {
    let errorTextArea = this.shadowRoot.querySelector('.error-text');
    if (error && error.length > 0) {
      errorTextArea.innerText = error;
    } else {
      errorTextArea.innerText = '';
    }
  }

  focus = (event) => {
    const currentCell = event.srcElement || event.target;
    currentCell.select();
  };

  keydown = (event) => {
    // handle alphanumeric keys
    if (this.isAlphaNumeric(event.key)) {
      event.preventDefault();
      return;
    }

    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        event.preventDefault();
    }
  };

  keyup = (event) => {
    const currentCell = event.srcElement || event.target;
    const pressedKey = event.key;

    if (pressedKey !== 'Tab') {
      event.preventDefault();
    }

    // handle alphanumeric keys
    if (this.isAlphaNumeric(pressedKey)) {
      currentCell.value = event.key.toUpperCase();
      this.goToNextCell(currentCell);
      return;
    }

    // handle special keys
    // note: fallthrough is intended
    switch (pressedKey) {
      case 'Delete':
        // only move focus to previous cell if there isn't anything to delete
        if (currentCell.value !== '') {
          currentCell.value = '';
          break;
        }
      case 'Backspace':
        // delete current cell and move focus to previous cell
        currentCell.value = '';
      case 'ArrowUp':
      case 'ArrowLeft':
        this.goToPreviousCell(currentCell);
        break;
      case 'ArrowDown':
      case 'ArrowRight':
        this.goToNextCell(currentCell);
        break;
      case 'Home':
        this.firstCell.focus();
        break;
      case 'End':
        this.lastCell.focus();
        break;
      default:
        break;
    }
  };

  // if debug is set as an attribute
  get debug() {
    return this.getAttribute('debug') === 'true';
  }
}

if (customElements.get('sagov-code-verifier') === undefined) {
  window.customElements.define('sagov-code-verifier', SaGovCodeVerifier);
}
