import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovFooter extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  connectedCallback() {
    const footerLinks = this.shadowRoot.querySelector('.footer-links');
    // add in all the links one at a time
    const accessibility = this.accessibility;

    footerLinks.appendChild(
      this.createFooterLink('Terms and conditions', 'https://www.sa.gov.au/dashboard/terms-and-conditions')
    );
    footerLinks.appendChild(this.createFooterLink('Privacy statement', 'https://www.sa.gov.au/dashboard/privacy'));
    if (accessibility && accessibility.trim()) {
      footerLinks.appendChild(this.createFooterLink('Accessibility', `https://www.sa.gov.au/${this.accessibility}`));
    }
    footerLinks.appendChild(
      this.createFooterLink('Acknowledgement of Country', 'https://www.sa.gov.au/acknowledgement-of-country')
    );
  }

  createFooterLink(label, url) {
    const li = document.createElement('li');
    const a = document.createElement('a');
    a.setAttribute('target', '_blank');
    a.setAttribute('href', url);
    a.innerText = label;
    li.appendChild(a);
    return li;
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  get accessibility() {
    return this.getAttribute('accessibility');
  }

  set accessibility(a) {
    this.setAttribute('accessibility', a);
  }
}

if (customElements.get('sagov-footer') === undefined) {
  window.customElements.define('sagov-footer', SaGovFooter);
}
