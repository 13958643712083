import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovRadioButton extends BaseSAGOVElement {
  constructor() {
    super(html);

    //        this.options = ['dark side', 'light side'];
  }

  static get observedAttributes() {
    return ['errortext', 'valid', 'required', 'label', 'placeholder', 'options'];
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  updateLabel() {
    let labelElement = this.shadowRoot.querySelector('.label-text');

    // handle the required property
    if (this.required) {
      labelElement.innerHTML = `${this.label}<sagov-required></sagov-required>`;
    } else {
      labelElement.innerHTML = this.label;
    }
  }

  /*
    Adds a tick to the end of an element in the shadow dom
    */
  addTick(element) {
    let tick = this.shadowRoot.querySelector('sagov-tick-icon');
    if (tick) {
      return;
    }
    tick = document.createElement('sagov-tick-icon');
    tick.setAttribute('type', 'success');
    element.appendChild(tick);
  }

  /*
        Removes a tick from the shadow dom if one exists.
    */
  removeTick() {
    let tick = this.shadowRoot.querySelector('sagov-tick-icon');
    if (tick && tick.parentNode !== null) {
      tick.parentNode.removeChild(tick);
    }
  }

  get inputContainer() {
    return this.shadowRoot.getElementById('input');
  }

  // allow access to the value of the input
  get value() {
    return this.shadowRoot.querySelector('input').value;
  }

  set value(v) {
    let input = this.shadowRoot.querySelector('input');
    if (v) {
      input.setAttribute('value', v);
    } else {
      input.setAttribute('value', '');
    }
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(_l) {
    this.updateLabel();
  }

  set placeholder(v) {
    let input = this.shadowRoot.querySelector('input');
    if (v) {
      input.setAttribute('placeholder', v);
    } else {
      input.removeAttribute('placeholder');
    }
  }

  set errortext(error) {
    let inputContainer = this.shadowRoot.getElementById('input');
    let errorTextArea = this.shadowRoot.querySelector('.error-text');
    if (error && error.length > 0) {
      errorTextArea.innerText = error;
      inputContainer.classList.add('error');
    } else {
      inputContainer.classList.remove('error');
    }
  }

  set options(options) {
    let inputContainer = this.shadowRoot.getElementById('input');

    let optArray = options.split(',');
    if (optArray) {
      let o = optArray.map((option, i) => {
        return `<div class="radio-option"><input type="radio" id="option-${i}" name="jedi" value="${option}"/><label for="option-${i}">${option}</label></div>`;
      });

      let h = o.join('');
      inputContainer.innerHTML = h;
    }
  }

  static get options() {
    return {
      options,
    };
  }

  // valid inputs show a tick and green highlight
  get valid() {
    return this.getAttribute('valid') || '';
  }

  set valid(v) {
    let inputContainer = this.shadowRoot.getElementById('input');

    if (v) {
      if (v === 'true') {
        inputContainer.classList.add('valid');
        inputContainer.classList.remove('error');
        this.addTick(inputContainer);
      } else if (v === 'false') {
        inputContainer.classList.remove('valid');
        inputContainer.classList.add('error');
        this.removeTick();
      }
    } else {
      inputContainer.classList.remove('valid');
      inputContainer.classList.remove('error');
      this.removeTick();
    }
  }

  // required input labels have a *
  get required() {
    return this.getAttribute('required') === 'true';
  }

  set required(_r) {
    this.updateLabel();
  }

  // if debug is set as an
  get debug() {
    return this.getAttribute('debug') === 'true';
  }
}

if (customElements.get('sagov-radio-button') === undefined) {
  window.customElements.define('sagov-radio-button', SaGovRadioButton);
}
