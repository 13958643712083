import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovNoteList extends BaseSAGOVElement {
  constructor() {
    super(html);
  }
}

if (customElements.get('sagov-note-list') === undefined) {
  window.customElements.define('sagov-note-list', SaGovNoteList);
}
