import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { createInstance, Piral, createStandardApi, Dashboard } from 'piral';
import { createDashboardApi } from 'piral-dashboard';
import { layout } from './layout';
import { errors } from './errors';
import { login } from './services/auth-service';
import loadScript from './services/load-script';
import { DashboardConfig } from './models/dashboard-config';
import '@dpc-odg/libs-web-design-system';
import { DashboardConfigService } from './services/config-service';
import TagManager from 'react-gtm-module';

const initFunction = async (): Promise<void> => {
  // Get the environment specific configuration
  const r = await fetch('./config.json');
  const dashboardConfig: DashboardConfig = await r.json();
  DashboardConfigService.FeedbackFormURL = dashboardConfig.FeedbackFormURL;
  DashboardConfigService.HelpUrl = dashboardConfig.HelpUrl;
  // create the react app
  const initApp = (): void => {
    const instance = createInstance({
      plugins: [...createStandardApi(), createDashboardApi()],
      state: {
        errorComponents: errors,
        components: layout,
        routes: {
          '/': Dashboard,
        },
      },
      requestPilets() {
        return fetch(dashboardConfig.PiralFeedUrl)
          .then((res) => res.json())
          .then((res) => res.items);
      },
    });
    if (dashboardConfig.GTMId != '') {
      const tagManagerArgs = {
        gtmId: dashboardConfig.GTMId,
      };
      TagManager.initialize(tagManagerArgs);
    }
    const appElement = document.querySelector('#app');
    const root = createRoot(appElement);
    root.render(<Piral instance={instance} />);
  };

  // first this we do is login (or be logged in), then redirect to the app
  // if the login fails, then false will be passed and the app needs to handle it.
  await loadScript('keycloak.js');
  await login({
    url: dashboardConfig.AuthUrl,
    realm: dashboardConfig.AuthRealm,
    clientId: dashboardConfig.AuthClientId,
  });

  // setup the piral dashboard
  initApp();
};

initFunction();
