{
  /* eslint-disable */
}
import { Menu } from 'piral';
import * as React from 'react';
import { Logout } from './logout';
import { DesignSystemHtmlElement, WrapperButton } from '@dpc-odg/libs-web-shared';
import { Constants } from '../constants/constants';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'sagov-cross-icon': DesignSystemHtmlElement;
      'sagov-bars-icon': DesignSystemHtmlElement;
    }
  }
}

/*
  The mobile menu
*/
export const MobileMenu: React.FunctionComponent = () => {
  const [open, setOpen] = React.useState(false);

  // note: here because there is no text for the menu item, narrator will ignore the button.
  // for this reason an &nbsp; has been added to force narrator to recognize the section.
  return (
    <div>
      {open ? (
        <WrapperButton
          ariaLabel={Constants.menuLabel}
          expanded={true}
          clickHandler={() => {
            setOpen(false);
          }}
        >
          <>
            <sagov-cross-icon title={Constants.menuLabel} aria-hidden="true" />
            &nbsp;
          </>
        </WrapperButton>
      ) : (
        <WrapperButton
          // note: The space here is so that narrator reads the new state of the menu on chrome
          ariaLabel={Constants.menuLabel + ' '}
          expanded={false}
          clickHandler={() => {
            setOpen(true);
          }}
        >
          <>
            <sagov-bars-icon title={Constants.menuLabel} aria-hidden="true" />
            &nbsp;
          </>
        </WrapperButton>
      )}
      {open ? (
        <div onClick={() => setOpen(false)} className="hamburger-menu">
          <Menu type="general" />
          <Logout />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
