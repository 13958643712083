import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovProgressBar extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  static get observedAttributes() {
    return ['progress'];
  }

  connectedCallback() {
    setTimeout(() => {
      this.container.innerHTML = this.innerHTML;
      this.updateProgress();
    });
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  get progress() {
    return this.getAttribute('progress') || '';
  }

  set progress(_p) {
    this.updateProgress();
  }

  updateProgress() {
    const nodes = this.shadowRoot.querySelectorAll('sagov-progress-node');
    const validStatusses = ['success', 'warning', 'error', 'custom'];

    if (this.progress == 'custom') {
      return;
    }

    for (const [index, node] of nodes.entries()) {
      node.removeAttribute('active');
      node.removeAttribute('status');

      if (index < this.progress) {
        node.setAttribute('active', '');

        if (nodes.length == this.progress) {
          node.setAttribute('status', 'success');
        }
      }

      if (validStatusses.includes(this.progress)) {
        node.setAttribute('active', '');
        node.setAttribute('status', this.progress);
      }
    }
  }
}

if (customElements.get('sagov-progress-bar') === undefined) {
  window.customElements.define('sagov-progress-bar', SaGovProgressBar);
}
