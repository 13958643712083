import * as React from 'react';
import styled from 'styled-components';

const ColumnStyled = styled.div`
  padding: var(--sagov-spacer-2) var(--sagov-spacer-3);
  font-weight: var(--sagov-font-weight-bold);
  box-sizing: border-box;
  min-width: 0px;
  cursor: pointer;
  background-color: var(--sagov-hover-blue);
`;

const RowStyled = styled.div`
  padding: var(--sagov-spacer-2) var(--sagov-spacer-3);
  box-sizing: border-box;
  min-width: 0px;
`;

const NoRowsStyled = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: var(--sagov-grey-color);

  &.no-rows[role='row'] {
    padding: 3%;
  }

  div {
    font-weight: var(--sagov-heading-font-weight);
  }

  @media (prefers-color-scheme: dark) {
    background-color: var(--sagov-black-color);
  }

  .icon-circle {
    display: inline-block;
    border-radius: 60px;
    background-color: var(--sagov-hover-blue);
    box-shadow: 0 0 1px var(--sagov-black-light-05);
    padding: 0.9em 0.6em;
    margin-bottom: var(--sagov-spacer-2);
  }
`;

export type TableElementType = React.FC<{ children: JSX.Element | string; className?: string }>;
export type NoRowsElementType = React.FC<{ message: string; icon: JSX.Element }>;

// A table header (equivilent of TH)
export const TableColumn = (props: {
  children: JSX.Element | string;
  className?: string;
  sortHandler: () => void;
}): JSX.Element => (
  <ColumnStyled role="columnheader" onClick={() => props.sortHandler()} className={`thead ${props.className}`}>
    {props.children}
  </ColumnStyled>
);

// A table element (equivilent of TD)
export const TableRow = (props: { children: JSX.Element | string; className?: string }): JSX.Element => (
  <RowStyled role="gridcell" className={props.className}>
    {props.children}
  </RowStyled>
);

// The section to show when there are no rows available
export const NoTableRows = (props: { message: string; icon: JSX.Element }): JSX.Element => (
  <NoRowsStyled className="no-rows" role="row">
    <div role="gridcell" aria-hidden="true">
      {props.icon}
    </div>
    <div role="gridcell" className="no-transaction-content">
      {props.message}
    </div>
  </NoRowsStyled>
);

// The section to show when the search produces no results
export const NoSearchRows = (props: { message: string }): JSX.Element => (
  <NoRowsStyled className="no-rows">
    <div className="no-transaction-content">{props.message}</div>
  </NoRowsStyled>
);
