/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useState } from 'react';
import { DesignSystemHtmlElement } from '../../../lib/constants/constants';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sagov-text-input': DesignSystemHtmlElement;
      'sagov-search-icon': DesignSystemHtmlElement;
    }
  }
}

interface TableSearchProps {
  globalFilter: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setGlobalFilter: any;
  placeholderText: string;
}

export const TableSearch = ({ globalFilter, setGlobalFilter, placeholderText }: TableSearchProps): JSX.Element => {
  const [value, setValue] = useState(globalFilter);
  React.useEffect(() => {
    setValue(globalFilter);
  }, [globalFilter]);
  const onChange = (value: string): void => {
    setGlobalFilter(value || undefined);
  };

  const updateSearch = (value: string): void => {
    setValue(value);
    window.setTimeout(async () => onChange(value), 200);
  };

  return (
    <section aria-label="Search">
      <search>
        <sagov-text-input
          value={value || ''}
          onKeyUp={(e: { target: { value: any } }) => updateSearch(e.target.value)}
          onClick={(e: { target: { value: any } }) => updateSearch(e.target.value)}
          placeholder={placeholderText}
          ariaLabel={placeholderText}
          clearlabel="Clear search text"
        >
          <sagov-search-icon aria-hidden="true" slot="prefix-icon" />
        </sagov-text-input>
      </search>
    </section>
  );
};
