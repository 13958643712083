import { SaGovTextInput } from '../sagov-input';
import html from 'raw-loader!./template.html';

export class SaGovAutocomplete extends SaGovTextInput {
  constructor() {
    super(html);
    this.options = [];
  }

  static get observedAttributes() {
    return [...super.observedAttributes];
  }

  connectedCallback() {
    this.shadowRoot.innerHTML += html;

    let optContainer = document.createElement('div');
    optContainer.classList = 'options';
    this.shadowRoot.querySelector('label').appendChild(optContainer);

    this.shadowRoot.querySelector('input').addEventListener('keyup', (e) => this.handleKeyup(e));
  }

  set options(_o) {
    this.destroyTheYounglings();

    _o.forEach((r) => {
      let c = document.createElement('option');
      c.innerText = r;
      this.shadowRoot.querySelector('.options').appendChild(c);
      c.addEventListener('click', (e) => this.setAutoValue(e));
    });
  }

  handleKeyup = (e) => {
    let val = e.target.value;

    if (val.length < 5) {
      this.destroyTheYounglings();
      return false;
    } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      let currentEl;
      this.shadowRoot.querySelector('.options').childNodes.forEach((c) => {
        if (c.hasAttribute('data-selected')) {
          currentEl = c;
        }
        c.removeAttribute('data-selected');
      });

      let nextItem;

      if (currentEl === null || currentEl === undefined) {
        nextItem = this.shadowRoot.querySelector('.options').childNodes[0];
      } else {
        if (e.key === 'ArrowDown') {
          nextItem = currentEl.nextElementSibling;
        }
        if (e.key === 'ArrowUp') {
          nextItem = currentEl.previousElementSibling;
        }
      }
      nextItem.setAttribute('data-selected', '');
      return false;
    } else if (e.key === 'Enter') {
      let currentEl;
      this.shadowRoot.querySelector('.options').childNodes.forEach((c) => {
        if (c.hasAttribute('data-selected')) {
          currentEl = c;
        }
      });
      currentEl.click();
      e.preventDefault();
    } else {
      // let event bubble to be caught by the implementation
      this.dispatchEvent(
        new CustomEvent('autocompleting', {
          detail: { value: this.value },
          composed: true,
          bubbles: true,
        })
      );
    }

    e.stopPropagation();
  };

  destroyTheYounglings() {
    let optContainer = this.shadowRoot.querySelector('.options');
    if (optContainer !== null && optContainer !== undefined) {
      while (optContainer.firstChild) {
        optContainer.removeChild(optContainer.firstChild);
      }
    }
  }

  setAutoValue = (e) => {
    this.shadowRoot.querySelector('input').value = e.target.textContent;
    this.destroyTheYounglings();
    this.dispatchEvent(
      new CustomEvent('autocompleted', {
        detail: { value: this.value },
        composed: true,
        bubbles: true,
      })
    );
  };
}

if (customElements.get('sagov-autocomplete') === undefined) {
  window.customElements.define('sagov-autocomplete', SaGovAutocomplete);
}
