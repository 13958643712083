import { ErrorComponentsState } from 'piral';
import * as React from 'react';
import { Link } from 'react-router-dom';

/*
    The error pages that will show for different error statuses.
*/
export const errors: Partial<ErrorComponentsState> = {
  not_found: () => (
    <div>
      <p className="error">Could not find the requested page. Are you sure it exists?</p>
      <p>
        Go back <Link to="/">to the dashboard</Link>.
      </p>
    </div>
  ),
};
