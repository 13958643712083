import { BaseSAGOVElement } from '../sagov-base-component';
import { svgMap } from '../icons/index';
import html from 'raw-loader!./template.html';

export class SaGovButton extends BaseSAGOVElement {
  static formAssociated = true;
  static get observedAttributes() {
    return ['disabled', 'type', 'text', 'size'];
  }

  constructor() {
    super(html);
    this.internals = this.attachInternals();
    this.internals.ariaRole = 'button';
  }

  insertButtonInnerHTML(el, iconleft = '', iconright = '') {
    // setTimeout creates a queue and enables us to call this in
    // connectedCallback as the innerHTML is not loaded at that stage
    setTimeout(() => (el.innerHTML = `${iconleft}${this.innerHTML}${iconright}`));
  }

  connectedCallback() {
    setTimeout(() => this.setAttribute('text', this.button.innerText), 0);

    this.insertButtonInnerHTML(this.button, svgMap[this.iconleft], svgMap[this.iconright]);
    this.setAttribute('role', 'button');

    this.button.setAttribute('type', this.action);

    if (this.action === 'submit') {
      this.button.addEventListener('click', () => {
        this.internals.form.requestSubmit();
      });
    }
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  get text() {
    return this.button.innerText;
  }

  set text(t) {
    this.button.innerText = t;
  }

  get iconleft() {
    return this.getAttribute('iconleft');
  }

  get iconright() {
    return this.getAttribute('iconright');
  }

  get button() {
    return this.container.querySelector('button');
  }

  get action() {
    return this.getAttribute('action') || 'button';
  }

  set type(t) {
    this.button.classList.remove('success', 'error', 'secondary', 'tertiary', 'secondary-success', 'secondary-error');
    if (t) {
      this.button.classList.add(t);
    }
  }

  set size(value) {
    this.button.classList.remove('medium');
    if (value) {
      this.button.classList.add(value);
    }
  }

  set disabled(d) {
    if (d === 'true') {
      this.button.classList.add('disabled');
      this.button.setAttribute('disabled', true);
    } else {
      this.button.classList.remove('disabled');
      this.button.removeAttribute('disabled');
      // WARNING! need to remove the disabled attribute from the web component as well
      // because when the element is form associated it causes events on the web component
      // to not work, even if disabled is set to false.
      this.removeAttribute('disabled');
      if (this.hasAttribute('onclick')) {
        this.setAttribute('onclick', this.getAttribute('onclick'));
      }
    }
  }
}

if (customElements.get('sagov-button') === undefined) {
  window.customElements.define('sagov-button', SaGovButton);
}
