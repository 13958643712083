/**
 * This functionality will allow multiple components to load before running an event.
 * Multiple events can be run after load is complete.
 */

// define global window variables for the loadComplete state.
declare global {
  interface Window {
    loadCompleteHandlers: { name: string; handler: () => void }[];
    loadCompleteSemaphore: number;
  }
}

/**
 * Create an event to run when all the page elements have finished loading their data.
 * Setting the same name event twice will overwrite the former value.
 *
 * @param {string} name The name of the handler to set.
 * @param {() => void} handler Then handler to run on load complete.
 */
export const addLoadCompleteHandler = (name: string, handler: () => void): void => {
  if (window.loadCompleteHandlers === undefined) {
    window.loadCompleteHandlers = [];
  }

  // remove the handler by name so we don't get duplicates
  const allButName = window.loadCompleteHandlers.filter((h) => h.name !== name);
  window.loadCompleteHandlers = [...allButName, { name: name, handler: handler }];
};

/**
 * Set the load complete value.
 * When a load is started, set to false
 * When a load is finished, set to true
 *
 * This is used for initial loads only for now.
 *
 * @param {boolean} complete Whether the load is complete or not.
 */
export const setLoadIsComplete = async (complete: boolean): Promise<void> => {
  if (window.loadCompleteSemaphore === undefined) {
    window.loadCompleteSemaphore = 0;
  }

  if (complete) {
    window.loadCompleteSemaphore--;
    if (window.loadCompleteSemaphore < 0) {
      window.loadCompleteSemaphore = 0;
    }

    // on each load complete, check if we are ready to run the handlers
    // set a timeout on this to give other pilets a chance to start up
    return new Promise((resolve) => {
      setTimeout(() => {
        if (window.loadCompleteSemaphore <= 0 && window.loadCompleteHandlers) {
          for (const handler of window.loadCompleteHandlers) {
            handler.handler();
          }
          // make sure the handlers aren't run again!
          window.loadCompleteHandlers = [];
          resolve();
        }
      }, 100);
    });
  } else {
    window.loadCompleteSemaphore++;
  }
  return Promise.resolve();
};
