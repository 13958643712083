import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovProgressNode extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  static get observedAttributes() {
    return ['active', 'status', 'label'];
  }

  connectedCallback() {}

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  get active() {
    return this.active;
  }

  set active(_a) {
    if (this.attrIsTrue(_a)) {
      this.container.classList.add('active');
    } else {
      this.container.classList.remove('active');
    }
  }

  get status() {
    return this.status;
  }

  set status(value) {
    const validStatusses = ['success', 'warning', 'error'];

    if (validStatusses.includes(value)) {
      this.container.classList.add(value);
    } else {
      this.container.classList.remove('status');
    }
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(_l) {
    const labelEl = this.shadowRoot.querySelector('.node-label');
    labelEl.textContent = _l;
  }
}

if (customElements.get('sagov-progress-node') === undefined) {
  window.customElements.define('sagov-progress-node', SaGovProgressNode);
}
