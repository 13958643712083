import { addLoadCompleteHandler } from './load-complete-helper';

/**
 * The name used for the scroll position parameter on the url.
 */
const ScrollParamName = 'scrollto';

/**
 * The timeout for scrolling, after this time scrolling will no longer occur.
 * This is the time between when the scroll handler is created and when it is invoked.
 * time is in milliseconds.
 */
const ScrollHandlerTimeout = 2000;

/**
 * Restore a set of parameters to a state. Each pilet can set their own set of parameters in theory
 * except the scroll which is a page level parameter. The scroll is set once (by the last pilet to update)
 * and will be called for each pilet, but the last pilet wins.
 *
 * @param {(p: URLSearchParams) => void)[]} stateSetters An array of functions that can set a value from a parameter.
 * @returns {Promise<void>}
 */
export const restoreFromUrl = async (stateSetters: ((p: URLSearchParams) => void)[]): Promise<void> => {
  const params = new URLSearchParams(location.search);

  // Run each of the state setters one at a time, and set a timeout to make sure the render has happened for each
  // this allows for changes to the state of the view, like table rows being removed etc..
  for await (const setter of stateSetters) {
    await new Promise((resolve) => {
      setter(params);
      setTimeout(resolve, 0);
    });
  }
};

/**
 * Set the current scroll position on the url. This is done before a click so that when coming back the current
 * position in the page is set.
 */
export const setCurrentScrollValueOnUrl = (): void => {
  replaceOnUrl(ScrollParamName, Math.floor(window.scrollY).toString());
};

/**
 * Create a handler that will run when the load is complete to restore the scroll position.
 */
export const createRestoreScrollPositionHandler = (): void => {
  const params = new URLSearchParams(location.search);

  // add in the scroll handler if it exists
  if (params.get(ScrollParamName)) {
    const scrollRestoreSetTime = new Date().getTime();
    addLoadCompleteHandler(ScrollParamName, () => {
      // if the time is past 2 seconds then don't scroll because it will
      // screw up the user experience.
      const scrollRestoreRunTime = new Date().getTime();
      if (scrollRestoreRunTime - scrollRestoreSetTime < ScrollHandlerTimeout) {
        params.get(ScrollParamName) &&
          window.scrollBy({ top: parseInt(params.get(ScrollParamName) || '0', 10), behavior: 'auto' });
      }

      // remove the scroll value to stop random scroll behaviour when opening dialogs.
      replaceOnUrl(ScrollParamName, null);
    });
  }
};

/**
 * Replace the current url with a parameter added or updated.
 *
 * @param {string} paramName the name of the parameter that is to be set on the url.
 * @param {string} paramValue the value of the paramter that is to be set on the url.
 */
export const replaceOnUrl = (paramName: string, paramValue: string | null): void => {
  let path = location.pathname + '?' + updateCurrentParams(paramName, paramValue);
  if (path.trim().endsWith('?')) {
    path = path.slice(0, -1);
  }
  window.history.replaceState(null, '', path);
};

/**
 * Update a paramter on the current parameters that are on the url.
 * If the value is blank then remove that parameter from the url instead.
 *
 * @param {string} paramName the name of the parameter that is to be set on the url.
 * @param {string} paramValue the value of the paramter that is to be set on the url.
 * @returns {URLSearchParams} The updated parameters
 */
const updateCurrentParams = (paramName: string, paramValue: string | null): URLSearchParams => {
  const currentParams = new URLSearchParams(location.search);
  if (paramValue) {
    currentParams.set(paramName, paramValue);
  } else {
    currentParams.delete(paramName);
  }
  return currentParams;
};
