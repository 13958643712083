/**
 * Used to load and execute javascript file. Suffers from same-domain restriction.
 * @param file JS file name
 * @param callback Subscribe to get notified when script file is loaded
 **/
export default function loadScript(file: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = file;
    script.onload = function () {
      resolve();
    };
    script.onerror = function (err) {
      reject(err);
    };
    document.body.appendChild(script);
  });
}
