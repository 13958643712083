import { Constants } from '../constants/constants';

/**
 * append the page title to base title - SA.GOV.AU
 * @param {string} title - title of individual pages.
 * @returns {string} data type string.
 */
export const appendPageTitle = (title: string): void => {
  document.title = `${Constants.basePageTitle} - ${title}`;
};
