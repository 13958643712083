import * as React from 'react';

export class Constants {
  static defaultLogoutUrl = 'https://sa.gov.au';
  static helpLabel = 'Help';
  static logoutLabel = 'Log out';
  static menuLabel = 'Menu';
  static errorHeader = 'Error';
  static betaBannerText = 'BETA';
  static dashboardPageTitle = 'Dashboard';
  static headerMessage = (props: { feedbackFormUrl: string }): JSX.Element => (
    <span>
      This is the SA.GOV.AU Dashboard beta.{' '}
      <a href={`${props.feedbackFormUrl}`} target="_blank" rel="noreferrer">
        Let us know what you think
      </a>
      .
    </span>
  );
}
