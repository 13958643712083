import * as React from 'react';
import { DesignSystemHtmlElement } from '../../lib/constants/constants';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'sagov-widget-error': DesignSystemHtmlElement;
    }
  }
}

/**
 * Component to show when an unrecoverable error occurs on a pilet.
 *
 * @param {string} errorLabel The message to show on the error.
 * @returns {JSX.Element}
 */
export function ErrorWidget(props: { errorLabel: string }): JSX.Element {
  return (
    <sagov-widget-error role="error" data-testid="pilet-loader-error" label={props.errorLabel}></sagov-widget-error>
  );
}
