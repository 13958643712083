import { BaseSAGOVElement } from '../sagov-base-component';
import timeline from 'raw-loader!./timeline.html';
import timelinerow from 'raw-loader!./timelinerow.html';

// The timeline component is a wrapper for timeline rows
// this is responsible for styling the circles and lines for the timeline.
export class SaGovTimeline extends BaseSAGOVElement {
  constructor() {
    super(timeline);
  }

  connectedCallback() {
    const complete = this.getAttribute('complete');
    if (complete === 'true') {
      this.container.classList.add('complete');
    }

    setTimeout(() => {
      this.container.innerHTML = this.innerHTML;
    });
  }
}

if (customElements.get('sagov-timeline') === undefined) {
  window.customElements.define('sagov-timeline', SaGovTimeline);
}

// The timeline row is used to draw an individual row in the timeline
// it is responsible for drawing and styling the data for that row.
export class SaGovTimelineRow extends BaseSAGOVElement {
  constructor() {
    super(timelinerow);
  }

  connectedCallback() {
    const status = this.getAttribute('status');
    const text = this.getAttribute('status-text');
    const date = this.getAttribute('date');
    const ariaLabel = this.getAttribute('arialabel');

    this.container.classList.add(status);
    const statusElement = this.container.querySelector('.status');
    statusElement.textContent = text;
    const dateElement = this.container.querySelector('.status-date');
    dateElement.textContent = date;
    const ariaElement = this.container.querySelector('.screenreader');
    ariaElement.textContent = ariaLabel;
  }
}

if (customElements.get('sagov-timeline-row') === undefined) {
  window.customElements.define('sagov-timeline-row', SaGovTimelineRow);
}
