import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';
// Create a class for the element
class SaGovAccordion extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  connectedCallback() {
    this.addEventListener('accordion-toggled', (e) => {
      if (!e.detail.open) {
        // skip details that are closing themselves
        // this is because opening one item can close others and then
        // they will close the one that just opened.
        return;
      }

      // close all the children
      var items = this.querySelectorAll('sagov-accordion-item');
      items[0].setAttribute('first', true);
      items.forEach((element) => {
        // skip the clicked item
        if (element.getAttribute('data-acc') != e.detail.accordionItem) {
          element.setAttribute('open', false);
        }
      });
    });
  }
}

// Define the new element
if (customElements.get('sagov-accordion') === undefined) {
  window.customElements.define('sagov-accordion', SaGovAccordion);
}
