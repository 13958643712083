import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovTag extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  static get observedAttributes() {
    return ['type'];
  }

  connectedCallback() {
    this.insertInnerHTML(this.textElement);
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  get textElement() {
    return this.shadowRoot.getElementById('text');
  }

  get text() {
    return this.getAttribute('text') || '';
  }

  set text(text) {
    let textSpan = this.shadowRoot.getElementById('text');
    textSpan.innerText = text;
  }

  get type() {
    return this.getAttribute('type') || 'default';
  }

  set type(type) {
    this.textElement.classList.remove('error', 'success', 'warning');
    if (type) {
      this.textElement.classList.add(type);
    }
  }
}

if (customElements.get('sagov-tag') === undefined) {
  window.customElements.define('sagov-tag', SaGovTag);
}
