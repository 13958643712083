import * as React from 'react';
import { Constants, appendPageTitle } from '@dpc-odg/libs-web-shared';
import { useEffect } from 'react';
import { Constants as DashboardConstants } from './../constants/constants';

/*
  Function to sort the widgets by their priority.
*/
const sortWidgets = (widgets: React.ReactElement[]): React.ReactElement | React.ReactElement[] => {
  if (widgets) {
    return widgets.sort(orderByPriority);
  }
  return <></>;
};

/*
  Function to filter the widgets by the view context (category), and section
  (the side of the dashboard they will be rendered on).
*/
const filterWidgets = (widgets: React.ReactElement[], side: string): React.ReactElement[] => {
  if (widgets) {
    return widgets.filter((w) => w.props.meta.customProperties?.includes(side));
  }
  return null;
};

/*
  Function to order the widgets (pilets) by priority.
  If the widget does not have a defined priority it is sent to the end of the list. 
  Defined priorities take preference.
*/
const orderByPriority = (a: React.ReactElement, b: React.ReactElement): number => {
  const maxPriority: number = Number.MAX_SAFE_INTEGER;
  const priorityA = a.props.meta.priority || maxPriority;
  const priorityB = b.props.meta.priority || maxPriority;
  return priorityA - priorityB;
};

/*
  The main section of the dashboard, where all the dashboard pilets are shown.
*/
export const DashboardContainer: React.FunctionComponent = (props) => {
  const widgets = props.children as React.ReactElement[];

  useEffect(() => {
    appendPageTitle(DashboardConstants.dashboardPageTitle);
  }, []);

  // draw the tiles
  return (
    <div className={'main-dashboard-container'}>
      <div className={Constants.fullWidthText}>{sortWidgets(filterWidgets(widgets, Constants.fullWidthText))}</div>
      <div className={'columns'}>
        <div className={Constants.leftSideText}>{sortWidgets(filterWidgets(widgets, Constants.leftSideText))}</div>
        <div className={Constants.rightSideText}>{sortWidgets(filterWidgets(widgets, Constants.rightSideText))}</div>
      </div>
    </div>
  );
};
