import { DesignSystemHtmlElement } from '@dpc-odg/libs-shared-web';
import * as React from 'react';
import styled from 'styled-components';
import { Constants } from '../constants/constants';
import { DashboardConfigService } from '../services/config-service';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'sagov-circlequestion-icon': DesignSystemHtmlElement;
    }
  }
}

const HelpStyle = styled.div`
  display: flex;
  align-items: center;

  a,
  label {
    font-weight: var(--sagov-font-weight-bold);
    font-size: var(--sagov-h4-font-size);
    color: var(--sagov-blue-color);
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    label {
      display: none;
    }
  }
`;

/**
  The help button
*/
export const Help: React.FunctionComponent = () => {
  return (
    <HelpStyle>
      <a href={DashboardConfigService.HelpUrl} target="_blank" rel="noreferrer">
        <sagov-circlequestion-icon title={Constants.helpLabel} aria-hidden="true" />
        {Constants.helpLabel}
      </a>
    </HelpStyle>
  );
};
