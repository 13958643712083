import * as React from 'react';
import { logout } from '@dpc-odg/libs-web-auth';
import { DashboardConfig } from '../models/dashboard-config';
import { Constants } from '../constants/constants';

/*
  The logout button
*/
export const Logout: React.FunctionComponent = () => {
  const [logoutUrl, setLogoutUrl] = React.useState(Constants.defaultLogoutUrl);

  React.useEffect(() => {
    // Get the environment specific configuration
    fetch('./config.json')
      .then((res) => res.json())
      .then((res) => {
        const config = res as DashboardConfig;
        setLogoutUrl(config.LogoutRedirectUrl);
      })
      .catch(() => {
        setLogoutUrl(Constants.defaultLogoutUrl);
      });
  }, []);

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="logout-link" href="#" onClick={() => logout(logoutUrl)}>
        {Constants.logoutLabel}
      </a>
    </div>
  );
};
