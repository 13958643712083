import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';
import { v4 } from 'uuid';

// Create a class for the element
class SaGovAccordionItem extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  connectedCallback() {
    // create a new id for each accordian item, so that we can open/close from the parent
    this.setAttribute('data-acc', v4());

    const details = this.details;
    details.addEventListener('toggle', () => {
      const selectEvent = new CustomEvent('accordion-toggled', {
        detail: {
          accordionItem: this.getAttribute('data-acc'),
          open: details.open,
        },
        bubbles: true,
        composed: true,
      });
      this.dispatchEvent(selectEvent);

      // update the attribute to allow external control
      this.setAttribute('open', details.open);
    });
  }

  static get observedAttributes() {
    return ['open', 'first'];
  }

  attributeChangedCallback(name, _oldValue, newValue) {
    if (name === 'open') {
      this.open = newValue === 'true';
    }
    if (name === 'first') {
      this.first = newValue === 'true';
    }
  }

  set first(_f) {
    if (_f) {
      this.details.setAttribute('first', true);
    }
  }

  get details() {
    return this.shadowRoot.querySelector('details');
  }

  set open(value) {
    let actionIcon = this.details.querySelector('.action-icon');
    let actionText = this.details.querySelector('.action-text');

    if (value) {
      this.details.setAttribute('open', true);
      actionText.textContent = 'Hide details';
      actionIcon.classList.add('rotated');
    } else {
      // this attribute must be removed as per https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details
      this.details.removeAttribute('open');
      actionText.textContent = 'View details';
      actionIcon.classList.remove('rotated');
    }
  }
}

// Define the new element
if (customElements.get('sagov-accordion-item') === undefined) {
  window.customElements.define('sagov-accordion-item', SaGovAccordionItem);
}
