import * as React from 'react';
import { DesignSystemHtmlElement } from '../../lib/constants/constants';
import styled from 'styled-components';
const { useEffect, useRef, useState } = React;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'sagov-information-alert': DesignSystemHtmlElement;
    }
  }
}
/**
 * The alert types that are allowed for the save response message.
 */
export type SaveResponseMessageType = 'warning' | 'error' | 'success';

const SlideUpTime = 350; // in ms

type MessageRefType = { offsetHeight: number };

const MessageDiv = styled.div<{ height: string }>`
  height: calc(${(props) => props.height} + var(--sagov-spacer-1));
  overflow: hidden;
  display: relative;
  padding-top: var(--sagov-spacer-1);
  padding-bottom: var(--sagov-spacer-1);

  &.hide {
    height: 0;
    transition: height ${SlideUpTime}ms linear;
    animation: scale-out-ver-top ${SlideUpTime}ms ease-in both;
  }

  @keyframes scale-out-ver-top {
    0% {
      transform: scaleY(1);
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      transform: scaleY(0);
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }
`;

/**
 * Show a message after saving a form.

 * @param {boolean} visible Show the message on the page. 
 * @param {SaveResponseMessageType} type The type of message to display.
 * @param {string} message The message to show.
 * @returns {JSX.Element} 
 */
export const SaveResponseMessage = (props: {
  visible: boolean;
  type: SaveResponseMessageType;
  message: string;
}): JSX.Element => {
  // use an inner visible state because the component is not hidden
  // immediately after the visible property is set
  const [visible, setVisible] = useState(false);

  // this state will set the style to 'hide' which triggers the hide animation
  const [hiding, setHiding] = useState(false);
  const [height, setHeight] = useState('0');
  const messageRef = useRef(null);

  useEffect(() => {
    if (props.visible) {
      setVisible(true);
      setTimeout(() => {
        // set the initial height of the outer area
        if (messageRef.current) {
          setHeight((messageRef.current as MessageRefType).offsetHeight + 'px');
        }
      }, 0);
    } else {
      setHiding(true);
      setTimeout(() => {
        setVisible(false);
        setHiding(false);
      }, SlideUpTime);
    }
  }, [props.visible]);

  // listen to height changes if the browser resizes, then the message will always look good
  useEffect(() => {
    if (!messageRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (messageRef.current) {
        setHeight((messageRef.current as MessageRefType).offsetHeight + 'px');
      }
    });
    resizeObserver.observe(messageRef.current);
    return () => resizeObserver.disconnect();
  }, [messageRef.current]);

  return (
    <>
      {visible && (
        <MessageDiv className={hiding ? 'hide' : ''} height={height} data-testid="save-response-message">
          <div ref={messageRef} aria-label={props.message}>
            <sagov-information-alert icon={props.type} label={props.message}></sagov-information-alert>
          </div>
        </MessageDiv>
      )}
    </>
  );
};
