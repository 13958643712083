import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovWidgetError extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  static get observedAttributes() {
    return ['label'];
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  updateLabel() {
    let labelElement = this.shadowRoot.querySelector('#textHolder');
    labelElement.innerHTML = `${this.label}`;
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(_l) {
    this.updateLabel();
  }

  // if debug is set as an
  get debug() {
    return this.getAttribute('debug') === 'true';
  }
}

if (customElements.get('sagov-widget-error') === undefined) {
  window.customElements.define('sagov-widget-error', SaGovWidgetError);
}
