/*
    Wrapper for the component auth library.
*/
import { initAuth, KeycloakInitValues, logout, getToken, getIdToken, getIdTokenParsed } from '@dpc-odg/libs-web-auth';

/*
    Override the login so that we deal with a promise intead of a callback.
*/
const login = (initValues: KeycloakInitValues): Promise<boolean> => {
  return new Promise((resolve, _reject) => {
    initAuth(initValues, (authenticated: boolean) => {
      resolve(authenticated);
    });
  });
};

/*
    Export the login functions that the dashboard might need, there are more functions available
    and they can be added as required.
*/
export { login, logout, getToken, getIdToken, getIdTokenParsed };
