export class GlobalStrings {
  // Table headings
  static headerProperty = 'Header';
  static cellProperty = 'Cell';

  static noSearchResultsText = `No results found`;

  static sortDescending = 'Sort in descending order';
  static sortAscending = 'Sort in ascending order';
  static sortDefault = 'Sort in default order';

  // Pagination
  static minRowsForPagination = 10;
  static defaultPageSize = 10;
  static ellipsis = '...';
  static numberOfPagesToShow = 7;

  static previousPage = 'Navigate to the previous page';
  static nextPage = 'Navigate to the next page';

  static numberOfResults = (pageSize: number, pageIndex: number, pageCount: number, allRowsCount: number): string => {
    const fromNumber = pageIndex * pageSize + 1;
    let toNumber = pageSize * (pageIndex + 1);
    if (pageIndex == pageCount - 1) {
      toNumber = allRowsCount;
    }
    return `${fromNumber} - ${toNumber} of ${allRowsCount}`;
  };
}
