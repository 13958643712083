import { BaseSAGOVElement } from '../sagov-base-component';
import html from 'raw-loader!./template.html';

export class SaGovStatusMessage extends BaseSAGOVElement {
  constructor() {
    super(html);
  }

  static get observedAttributes() {
    return ['message'];
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.debug) {
      console.log(`changing attribute ${attributeName} from ${oldValue} to ${newValue}`);
    }
    this[attributeName] = newValue;
  }

  get message() {
    return this.getAttribute('message');
  }

  set message(_m) {
    this.shadowRoot.querySelector('.message-container').innerText = _m;
  }
}

if (customElements.get('sagov-status-message') === undefined) {
  window.customElements.define('sagov-status-message', SaGovStatusMessage);
}
